import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  receivedMessage: null,
  socket: null,
};

const webSocketSlice = createSlice({
  name: "webSocket",
  initialState,
  reducers: {
    addReceivedMessage: (state, action) => {
      state.receivedMessage = [action.payload, ...state.receivedMessage];
    },
    markAsReadReceivedMessage: (state, action) => {
      state.receivedMessage = state.receivedMessage.filter(
        item => !action.payload.some(obj => obj.id === item.id),
      );
    },
    setReceivedMessage: (state, action) => {
      state.receivedMessage = action.payload;
    },
  },
});

export const { addReceivedMessage, markAsReadReceivedMessage, setReceivedMessage } =
  webSocketSlice.actions;

export default webSocketSlice.reducer;
