import { apiSlice } from "./apiSlice";
import {
  createMultipleProductAlias,
  createMultipleProductCategories,
  createMultipleProductPlan,
  createMultipleProducts,
  createMultipleProductTags,
  createProduct,
  createProductAlias,
  createProductCategory,
  createProductPlan,
  createProductTag,
  getDownloadedProductAlias,
  getDownloadedProductCategories,
  getDownloadedProductPlans,
  getDownloadedProducts,
  getDownloadedProductTags,
  getProductAcquisitionCode,
  getProductAlias,
  getProductAliasFields,
  getProductBasedWareCosting,
  getProductCategories,
  getProductCategoryFields,
  getProductFields,
  getProductOrderFrequency,
  getProductPlanFields,
  getProductPlanningPolicy,
  getProductPlans,
  getProductReOrderPlanMethod,
  getProducts,
  getProductsBasedFromWarehouse,
  getProductsBasedSupplier,
  getProductStatus,
  getProductStockLocation,
  getProductStorageMethod,
  getProductTags,
  getProductTagsFields,
  getProductType,
  updateMultipleProductAlias,
  updateMultipleProductCategories,
  updateMultipleProductPlans,
  updateMultipleProducts,
  updateMultipleProductTags,
  updateProduct,
  updateProductAlias,
  updateProductCategory,
  updateProductPlan,
  updateProductTag,
} from "../slice/productSlice";

export const productApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    createMultipleProductAlias: builder.mutation({
      query: data => ({
        url: "/api_product/product_alias/create_multiple_product_alias/",
        method: "post",
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(createMultipleProductAlias(result.data.data)))
          .catch(error => {});
      },
    }),
    createMultipleProductCategories: builder.mutation({
      query: data => ({
        url: "/api_product/product_category/create_multiple_produt_category/",
        method: "post",
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(createMultipleProductCategories(result.data.data)))
          .catch(error => {});
      },
    }),
    createMultipleProductPlans: builder.mutation({
      query: data => ({
        url: "/api_product/product_plan/create_multiple_product_plans/",
        method: "post",
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(createMultipleProductPlan(result.data.data)))
          .catch(error => {});
      },
    }),
    createMultipleProducts: builder.mutation({
      query: data => ({
        url: "/api_product/product/create_multiple_produts/",
        method: "post",
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(createMultipleProducts(result.data.data)))
          .catch(error => {});
      },
    }),
    createMultipleProductTags: builder.mutation({
      query: data => ({
        url: "/api_product/product_tags/create_multiple_produt_tag/",
        method: "post",
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(createMultipleProductTags(result.data.data)))
          .catch(error => {});
      },
    }),
    createProductAlias: builder.mutation({
      query: data => ({
        url: "/api_product/product_alias/create_product_alias/",
        method: "post",
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(createProductAlias(result.data.data)))
          .catch(error => {});
      },
    }),
    createProductCategory: builder.mutation({
      query: data => ({
        url: "/api_product/product_category/create_product_category/",
        method: "post",
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(createProductCategory(result.data.data)))
          .catch(error => {});
      },
    }),
    createProductPlan: builder.mutation({
      query: data => ({
        url: "/api_product/product_plan/create_product_plan/",
        method: "post",
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(createProductPlan(result.data.data)))
          .catch(error => {});
      },
    }),
    createProducts: builder.mutation({
      query: data => ({ url: "/api_product/product/create_product/", method: "post", body: data }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => dispatch(createProduct(result.data.data))).catch(error => {});
      },
    }),
    createProductTag: builder.mutation({
      query: data => ({
        url: "/api_product/product_tags/create_product_tag/",
        method: "post",
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(createProductTag(result.data.data)))
          .catch(error => {});
      },
    }),
    getDownloadedProductAlias: builder.query({
      query: params =>
        params
          ? `/api_product/product_alias/download_records/?${params}`
          : "/api_product/product_alias/download_records/",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(getDownloadedProductAlias(result.data.data)))
          .catch(error => {});
      },
    }),
    getDownloadedProductCategories: builder.query({
      query: params =>
        params
          ? `/api_product/product_category/download_records/?${params}`
          : "/api_product/product_category/download_records/",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(getDownloadedProductCategories(result.data.data)))
          .catch(error => {});
      },
    }),
    getDownloadedProductPlans: builder.query({
      query: params =>
        params
          ? `/api_product/product_plan/download_records/?${params}`
          : "/api_product/product_plan/download_records/",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(getDownloadedProductPlans(result.data.data)))
          .catch(error => {});
      },
    }),
    getDownloadedProducts: builder.query({
      query: params =>
        params
          ? `/api_product/product/download_records/?${params}`
          : "/api_product/product/download_records/",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(getDownloadedProducts(result.data.data)))
          .catch(error => {});
      },
    }),
    getDownloadedProductTags: builder.query({
      query: params =>
        params
          ? `/api_product/product_tags/download_records/?${params}`
          : "/api_product/product_tags/download_records/",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(getDownloadedProductTags(result.data.data)))
          .catch(error => {});
      },
    }),
    getLeadTimeBasedFromWarehouse: builder.query({
      query: params => `/api_product/get_leades_from_warehouse/?${params}`,
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => {}).catch(error => {});
      },
    }),
    getLeadTimeBasedProdSuppWare: builder.query({
      query: params => `/api_product/get_product_lead/?${params}`,
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => {}).catch(error => {});
      },
    }),
    getProductAcquisitionCode: builder.query({
      query: () => "/api_product/product_acquisition/",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(getProductAcquisitionCode(result.data)))
          .catch(error => {});
      },
    }),
    getProductAlias: builder.query({
      query: (params = null) =>
        params ? `/api_product/product_alias/?${params}` : "/api_product/product_alias/",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => dispatch(getProductAlias(result.data))).catch(error => {});
      },
    }),
    getProductAliasFields: builder.query({
      query: () => "/api_product/product_alias_fields/",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(getProductAliasFields(result.data)))
          .catch(error => {});
      },
    }),
    getProductBasedWareCosting: builder.query({
      query: (params = null) => `/api_product/get_products_warehouse_costing`,
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(getProductBasedWareCosting(result.data.data)))
          .catch(error => {});
      },
    }),
    getProductCategories: builder.query({
      query: (params = null) =>
        params ? `/api_product/product_category/?${params}` : "/api_product/product_category/",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(getProductCategories(result.data)))
          .catch(error => {});
      },
    }),
    getProductCategoryFields: builder.query({
      query: () => "/api_product/product_category_fields/",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(getProductCategoryFields(result.data)))
          .catch(error => {});
      },
    }),
    getProductFields: builder.query({
      query: () => "/api_product/product_fields/",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => dispatch(getProductFields(result.data))).catch(error => {});
      },
    }),
    getProductOrderFrequency: builder.query({
      query: () => "/api_product/product_order_frequency/",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(getProductOrderFrequency(result.data)))
          .catch(error => {});
      },
    }),
    getProductPlanFields: builder.query({
      query: () => "/api_product/product_plan_fields/",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(getProductPlanFields(result.data)))
          .catch(error => {});
      },
    }),
    getProductPlanningPolicy: builder.query({
      query: () => "/api_product/product_planning_policy/",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(getProductPlanningPolicy(result.data)))
          .catch(error => {});
      },
    }),
    getProductPlans: builder.query({
      query: (params = null) =>
        params ? `/api_product/product_plan/?${params}` : "/api_product/product_plan/",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => dispatch(getProductPlans(result.data))).catch(error => {});
      },
    }),
    getProductReOrderPlanMethod: builder.query({
      query: () => "/api_product/product_re_order/",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(getProductReOrderPlanMethod(result.data)))
          .catch(error => {});
      },
    }),
    getProducts: builder.query({
      query: (params = null) =>
        params ? `/api_product/product/?${params}` : "/api_product/product/",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => dispatch(getProducts(result.data))).catch(error => {});
      },
    }),
    getProductsBasedFromWarehouse: builder.query({
      query: params => `/api_product/get_products_from_warehouse/?${params}`,
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(getProductsBasedFromWarehouse(result.data)))
          .catch(error => {});
      },
    }),
    getProductsBasedSupplier: builder.query({
      query: params => `/api_product/get_products_from_warehouse/?${params}`,
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(getProductsBasedSupplier(result.data)))
          .catch(error => {});
      },
    }),
    getProductStatus: builder.query({
      query: () => "/api_product/product_status/",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => dispatch(getProductStatus(result.data))).catch(error => {});
      },
    }),
    getProductStockLocation: builder.query({
      query: () => "/api_product/product_stock_location/",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(getProductStockLocation(result.data)))
          .catch(error => {});
      },
    }),
    getProductStorageMethod: builder.query({
      query: () => "/api_product/product_storage_method/",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(getProductStorageMethod(result.data)))
          .catch(error => {});
      },
    }),
    getProductTags: builder.query({
      query: (params = null) =>
        params ? `/api_product/product_tags/?${params}` : "/api_product/product_tags/",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => dispatch(getProductTags(result.data))).catch(error => {});
      },
    }),
    getProductTagsFields: builder.query({
      query: () => "/api_product/product_tags_fields/",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(getProductTagsFields(result.data)))
          .catch(error => {});
      },
    }),
    getProductType: builder.query({
      query: () => "/api_product/product_type/",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => dispatch(getProductType(result.data))).catch(error => {});
      },
    }),
    getProductUnitPrice: builder.query({
      query: params => `/api_product/get_unit_price/?${params}`,
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => {}).catch(error => {});
      },
    }),
    syncProduct: builder.mutation({
      query: () => ({ url: "/api_product/sync_product/", method: "POST", body: {} }),
    }),
    syncProductAlias: builder.mutation({
      query: () => ({ url: "/api_product/sync_product_alias/", method: "POST", body: {} }),
    }),
    updateMultipleProductAlias: builder.mutation({
      query: data => ({
        url: "/api_product/product_alias/update_downloaded_product_alias/",
        method: "PATCH",
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(updateMultipleProductAlias(result.data.data)))
          .catch(error => {});
      },
    }),
    updateMultipleProductCategories: builder.mutation({
      query: data => ({
        url: "/api_product/product_category/update_downloaded_product_categories/",
        method: "PATCH",
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(updateMultipleProductCategories(result.data.data)))
          .catch(error => {});
      },
    }),
    updateMultipleProductPlans: builder.mutation({
      query: data => ({
        url: "/api_product/product_plan/update_downloaded_product_plans/",
        method: "PATCH",
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(updateMultipleProductPlans(result.data.data)))
          .catch(error => {});
      },
    }),
    updateMultipleProducts: builder.mutation({
      query: data => ({
        url: "/api_product/product/update_downloaded_products/",
        method: "PATCH",
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(updateMultipleProducts(result.data.data)))
          .catch(error => {});
      },
    }),
    updateMultipleProductTags: builder.mutation({
      query: data => ({
        url: "/api_product/product_tags/update_downloaded_product_tag/",
        method: "PATCH",
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(updateMultipleProductTags(result.data.data)))
          .catch(error => {});
      },
    }),
    updateProductAlias: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api_product/product_alias/${id}/update_product_alias/`,
        method: "PATCH",
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(updateProductAlias(result.data.data)))
          .catch(error => {});
      },
    }),
    updateProductCategory: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api_product/product_category/${id}/update_product_category/`,
        method: "PATCH",
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(updateProductCategory(result.data.data)))
          .catch(error => {});
      },
    }),
    updateProductPlan: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api_product/product_plan/${id}/update_product_plan/`,
        method: "PATCH",
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(updateProductPlan(result.data.data)))
          .catch(error => {});
      },
    }),
    updateProducts: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api_product/product/${id}/update_product/`,
        method: "PATCH",
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => dispatch(updateProduct(result.data.data))).catch(error => {});
      },
    }),
    updateProductTag: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api_product/product_tags/${id}/update_product_tag/`,
        method: "PATCH",
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(updateProductTag(result.data.data)))
          .catch(error => {});
      },
    }),
  }),
});

export const {
  useCreateMultipleProductAliasMutation,
  useCreateMultipleProductCategoriesMutation,
  useCreateMultipleProductPlansMutation,
  useCreateMultipleProductsMutation,
  useCreateMultipleProductTagsMutation,
  useCreateProductAliasMutation,
  useCreateProductCategoryMutation,
  useCreateProductPlanMutation,
  useCreateProductsMutation,
  useCreateProductTagMutation,
  useGetProductAcquisitionCodeQuery,
  useGetProductAliasFieldsQuery,
  useGetProductAliasQuery,
  useGetProductBasedWareCostingQuery,
  useGetProductCategoriesQuery,
  useGetProductCategoryFieldsQuery,
  useGetProductFieldsQuery,
  useGetProductOrderFrequencyQuery,
  useGetProductPlanFieldsQuery,
  useGetProductPlanningPolicyQuery,
  useGetProductPlansQuery,
  useGetProductReOrderPlanMethodQuery,
  useGetProductsQuery,
  useGetProductStatusQuery,
  useGetProductStockLocationQuery,
  useGetProductStorageMethodQuery,
  useGetProductTagsFieldsQuery,
  useGetProductTagsQuery,
  useGetProductTypeQuery,
  useLazyGetDownloadedProductAliasQuery,
  useLazyGetDownloadedProductCategoriesQuery,
  useLazyGetDownloadedProductPlansQuery,
  useLazyGetDownloadedProductsQuery,
  useLazyGetDownloadedProductTagsQuery,
  useLazyGetLeadTimeBasedFromWarehouseQuery,
  useLazyGetLeadTimeBasedProdSuppWareQuery,
  useLazyGetProductsBasedFromWarehouseQuery,
  useLazyGetProductsBasedSupplierQuery,
  useLazyGetProductUnitPriceQuery,
  useSyncProductAliasMutation,
  useSyncProductMutation,
  useUpdateMultipleProductAliasMutation,
  useUpdateMultipleProductCategoriesMutation,
  useUpdateMultipleProductPlansMutation,
  useUpdateMultipleProductsMutation,
  useUpdateMultipleProductTagsMutation,
  useUpdateProductAliasMutation,
  useUpdateProductCategoryMutation,
  useUpdateProductPlanMutation,
  useUpdateProductsMutation,
  useUpdateProductTagMutation,
} = productApiSlice;
