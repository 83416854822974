import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  groups: [],

  user: null,
  users: [],
  usersParams: { limit: 10, offset: 0 },

  userSelect: null,
  usersSelect: [],
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    getUsers: (state, action) => {
      state.users = action.payload;
    },
    resetUsers: state => {
      state.users = [];
    },
    resetUserSelect: state => {
      state.userSelect = initialState.userSelect;
    },
    resetUsersParams: state => {
      state.usersParams = { limit: state.usersParams.limit, offset: 0 };
    },
    resetUsersSelect: state => {
      state.usersSelect = initialState.usersSelect;
    },
    setGroups: (state, action) => {
      state.groups = action.payload;
    },
    setUserSelect: (state, action) => {
      state.userSelect = action.payload;
    },
    setUsersParams: (state, action) => {
      state.usersParams = action.payload;
    },
    setUsersSelect: (state, action) => {
      state.usersSelect = action.payload;
    },
    updateUsersParams: (state, action) => {
      state.usersParams = { ...state.usersParams, ...action.payload };
    },
    updateUserStatus: (state, action) => {
      const index = state.users.results.findIndex(user => user.id === action.payload.id);
      if (index !== -1) {
        state.users.results[index] = {
          ...state.users.results[index],
          is_active: action.payload.is_active,
        };
      }
    },
  },
});

export const {
  getUsers,
  resetUsers,
  resetUserSelect,
  resetUsersParams,
  resetUsersSelect,
  setGroups,
  setUserSelect,
  setUsersParams,
  setUsersSelect,
  updateUsersParams,
  updateUserStatus,
} = userSlice.actions;

export default userSlice.reducer;

export const users = state => state.user.users;
