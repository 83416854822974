import React from "react";
import { AppRoutes } from "./routes/AppRoutes";
import { Chart, registerables } from "chart.js";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./store/store";
import "./_metronic/assets/fonticon/fonticon.css";
import "./_metronic/assets/keenicons/duotone/style.css";
import "./_metronic/assets/keenicons/outline/style.css";
import "./_metronic/assets/keenicons/solid/style.css";
import "./_metronic/assets/sass/style.scss";
import "./_metronic/assets/sass/plugins.scss";
import "./_metronic/assets/sass/style.react.scss";
import "react-toastify/dist/ReactToastify.css";
import "react-datetime/css/react-datetime.css";
import "./assets/css/common.css";

Chart.register(...registerables);

const container = document.getElementById("root");
if (container) {
  createRoot(container).render(
    <React.StrictMode>
      <Provider store={store}>
        <AppRoutes />
      </Provider>
    </React.StrictMode>,
  );
}
