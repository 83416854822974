import clsx from "clsx";
import { useLayout } from "../../core";
import { Link } from "react-router-dom";
import { HeaderToolbar } from "./HeaderToolbar";
import { KTIcon, toAbsoluteUrl } from "../../../helpers";

export function HeaderWrapper() {
  const { config, classes, attributes } = useLayout();
  const { aside } = config;

  return (
    <div
      id="kt_header"
      className={clsx("header", classes.header.join(" "), "align-items-stretch")}
      {...attributes.headerMenu}
    >
      <div className="header-brand d-lg-none">
        <Link to="/">
          <img
            alt="Logo"
            src={toAbsoluteUrl("/media/orderlink_logos/Blue.svg")}
            className="ms-md-10 h-100px h-lg-100px"
          />
        </Link>

        {aside.minimize && (
          <div
            id="kt_aside_toggle"
            className="btn btn-icon w-auto px-0 btn-active-color-primary aside-minimize"
            data-kt-toggle="true"
            data-kt-toggle-state="active"
            data-kt-toggle-target="body"
            data-kt-toggle-name="aside-minimize"
          >
            <KTIcon iconName="exit-left" className="fs-1 me-n1 minimize-default" />
            <KTIcon iconName="entrance-left" className="fs-1 minimize-active" />
          </div>
        )}

        <div className="d-flex align-items-center d-lg-none ms-n3 me-1" title="Show aside menu">
          <div
            className="btn btn-icon btn-active-color-primary w-30px h-30px"
            id="kt_aside_mobile_toggle"
          >
            <KTIcon iconName="abstract-14" className="fs-1" />
          </div>
        </div>
      </div>

      <HeaderToolbar />
    </div>
  );
}
