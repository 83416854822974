import { CreatePurchaseOrderAction } from "../components/PurchaseOrderAction";
import { UploadPurchaseOrder } from "../components/UploadPurchaseOrder";

const GlobalListModals = () => {
  return (
    <>
      <CreatePurchaseOrderAction />
      <UploadPurchaseOrder />
    </>
  );
};

export { GlobalListModals };
