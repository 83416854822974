import { authSuccess, logOut } from "./authSlice";
import {
  setForecastsParams,
  setStockOnHandParams,
  setWarehouseCostingParams,
  setWarehouseCostingRangeParams,
} from "./warehouseSlice";
import { setProductsParams, setProductPlansParams, setProductAliasParams } from "./productSlice";
import { setWarehousesParams, setSuppliersParams } from "./supplierSlice";
import { updateASNListParams, updateConsignmentsParams } from "./shipmentSlice";
import { updateFilesParams } from "./fileSlice";
import { updatePlannedOrdersParams } from "./plannerSlice";
import { updatePOWatchlistLateParams, updatePurchaseOrdersParams } from "./purchaseOrderSlice";
import {
  updatePurchaseAllocationsParams,
  updateTransferAllocationsParams,
  updateTransferOrderLinesParams,
} from "./orderSlice";
import { updateSettings } from "./miscSlice";

/**
 * Updates pagination parameters for various tables in the application.
 * This function dynamically sets items-per-page limits based on provided props,
 * and dispatches only necessary updates to the Redux store, reducing unnecessary state updates.
 *
 * @param {Object} props - Object containing various pagination limits.
 * @param {function} dispatch - Redux dispatch function to update store state.
 */
const updateParams = (props, dispatch) => {
  // Mapping of property keys to their corresponding dispatch actions
  const paramsMap = {
    asnList_items_per_page: updateASNListParams,
    files_items_per_page: updateFilesParams,
    international_shipments_items_per_page: updateConsignmentsParams,
    master_forecast_items_per_page: setForecastsParams,
    master_product_alias_items_per_page: setProductAliasParams,
    master_product_plan_items_per_page: setProductPlansParams,
    master_products_items_per_page: setProductsParams,
    master_stock_on_hand_items_per_page: setStockOnHandParams,
    master_suppliers_items_per_page: setSuppliersParams,
    master_warehouse_costing_items_per_page: setWarehouseCostingParams,
    master_warehouse_costing_range_items_per_page: setWarehouseCostingRangeParams,
    master_warehouses_items_per_page: setWarehousesParams,
    planned_orders_items_per_page: updatePlannedOrdersParams,
    po_items_per_page: updatePurchaseOrdersParams,
    po_watchlist_items_per_page: updatePOWatchlistLateParams,
    purchase_allocations_items_per_page: updatePurchaseAllocationsParams,
    purchase_orders_items_per_page: updatePurchaseOrdersParams,
    transfer_allocations_items_per_page: updateTransferAllocationsParams,
    transfer_order_lines_items_per_page: updateTransferOrderLinesParams,
  };

  // Loop through the paramsMap to dynamically dispatch updates
  Object.entries(paramsMap).forEach(([propKey, action]) => {
    const limit = props[propKey];
    if (limit) {
      dispatch(action({ limit }));
    }
  });
};

const updatePrecision = (precision, dispatch) => {
  if (precision) {
    dispatch(updateSettings({ precision }));
  }
};

export const authenticationFailed = () => dispatch => {
  localStorage.setItem("authOut", Date.now());
  dispatch(logOut());
};

export const authenticationSuccess = data => dispatch => {
  localStorage.setItem("authIn", Date.now());
  dispatch(authSuccess(data));
  updateParams(data.limit, dispatch);
  updatePrecision(data.precision, dispatch);
};

export const logOutUser = () => dispatch => {
  localStorage.setItem("authOut", Date.now());
  dispatch(logOut());
};

export const authOnSuccess = data => dispatch => {
  dispatch(authSuccess(data));
  updateParams(data.limit, dispatch);
  updatePrecision(data.precision, dispatch);
};
