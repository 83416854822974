import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  bottomDifotMetrics: [],
  topDifotMetrics: [],

  bottomLeadTimeMetrics: [],
  topLeadTimeMetrics: [],

  difotDateRangeSelection: { startDate: "", endDate: "", reset: false, key: "difot_metrics" },
  leadDateRangeSelection: { startDate: "", endDate: "", reset: false, key: "lead_time_metrics" },
  metricsDateRangeSelection: { startDate: "", endDate: "", reset: false, key: "metrics" },
  supplierDateRangeSelection: { startDate: "", endDate: "", reset: false, key: "supplier_metrics" },

  metricsCriteria: {},

  supplier: null,
  suppliers: { count: 0, results: [] },
  suppliersParams: { limit: 10, offset: 0 },
  suppliersSelection: [],
  downloadedSuppliers: [],

  supplierMetrics: {
    failOrders: [],
    lead_time_data: [],
    lead_time_metrics: {},
    matrix: {},
    passOrders: [],
    supplier: {},
  },

  topSuppliersSpend: [],

  warehouse: null,
  warehouses: { count: 0, results: [] },
  warehousesParams: { limit: 10, offset: 0 },
  warehousesSelection: [],

  warehouseType: [],
  suppliersFields: [],
  warehouseFields: [],
  downloadedWarehouses: [],

  warehouseBasedWareCosting: [],
};

const supplierSlice = createSlice({
  name: "supplier",
  initialState,
  reducers: {
    createMultipleSuppliers: (state, action) => {
      state.suppliers.count += action.payload.length;
      state.suppliers.results = [...state.suppliers.results, ...action.payload];
    },
    createMultipleWarehouses: (state, action) => {
      state.warehouses.count += action.payload.length;
      state.warehouses.results = [...state.warehouses.results, ...action.payload];
    },
    createSuppliers: (state, action) => {
      state.suppliers.count += action.payload.length;
      state.suppliers.results = [...state.suppliers.results, action.payload];
    },
    createWarehouses: (state, action) => {
      state.warehouses.count += action.payload.length;
      state.warehouses.results = [...state.warehouses.results, action.payload];
    },
    getBottomDifotMetrics: (state, action) => {
      state.bottomDifotMetrics = action.payload;
    },
    getBottomLeadMetrics: (state, action) => {
      state.bottomLeadTimeMetrics = action.payload;
    },
    getDownloadedSuppliers: (state, action) => {
      state.downloadedSuppliers = action.payload;
    },
    getDownloadedWarehouses: (state, action) => {
      state.downloadedWarehouses = action.payload;
    },
    getMetricsCriteria: (state, action) => {
      state.metricsCriteria = action.payload;
    },
    getSupplierMetrics: (state, action) => {
      state.supplierMetrics = {
        supplier: action.payload.supplier,
        matrix: action.payload.matrix,
        passOrders: action.payload.pass_data,
        failOrders: action.payload.fail_data,
        lead_time_data: action.payload.lead_time_data,
        lead_time_metrics: action.payload.lead_time_metrics,
      };
    },
    getSuppliers: (state, action) => {
      state.suppliers = action.payload;
    },
    getSuppliersFields: (state, action) => {
      state.suppliersFields = action.payload;
    },
    getTopDifotMetrics: (state, action) => {
      state.topDifotMetrics = action.payload;
    },
    getTopLeadMetrics: (state, action) => {
      state.topLeadTimeMetrics = action.payload;
    },
    getTopSuppliersSpend: (state, action) => {
      state.topSuppliersSpend = action.payload;
    },
    getWarehouseBasedWareCosting: (state, action) => {
      state.warehouseBasedWareCosting = action.payload;
    },
    getWarehouseFields: (state, action) => {
      state.warehouseFields = action.payload;
    },
    getWarehouses: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.warehousesSelection = action.payload;
      } else {
        state.warehouses = action.payload;
      }
    },
    getWarehouseType: (state, action) => {
      state.warehouseType = action.payload;
    },
    resetSupplierMetrics: state => {
      state.supplierMetrics = initialState.supplierMetrics;
    },
    resetSuppliers: state => {
      state.suppliers = initialState.suppliers;
    },
    resetSuppliersParams: state => {
      state.suppliersParams = state.warehousesParams = {
        limit: state.warehousesParams.limit,
        offset: 0,
      };
    },
    resetSuppliersSelection: state => {
      state.suppliersSelection = initialState.suppliersSelection;
    },
    resetwarehouse: state => {
      state.warehouse = initialState.warehouse;
    },
    resetWarehousesSelection: (state, action) => {
      state.warehousesSelection = initialState.warehousesSelection;
    },
    resetWarehouseBasedWareCosting: (state, action) => {
      state.warehouseBasedWareCosting = initialState.warehouseBasedWareCosting;
    },
    resetwarehouses: state => {
      state.warehouses = initialState.warehouses;
    },
    resetWarehousesParams: state => {
      state.warehousesParams = { limit: state.warehousesParams.limit, offset: 0 };
    },
    resetWarehouseType: state => {
      state.warehouseType = initialState.warehouseType;
    },
    setDifotDateSelectionRange: (state, action) => {
      state.difotDateRangeSelection = action.payload;
    },
    setLeadDateSelectionRange: (state, action) => {
      state.leadDateRangeSelection = action.payload;
    },
    setMetricsDateRangeSelection: (state, action) => {
      state.metricsDateRangeSelection = action.payload;
    },
    setSupplierDateSelectionRange: (state, action) => {
      state.supplierDateRangeSelection = action.payload;
    },
    setSuppliersParams: (state, action) => {
      state.suppliersParams = action.payload;
    },
    setSuppliersSelection: (state, action) => {
      state.suppliersSelection = action.payload;
    },
    setWarehouse: (state, action) => {
      state.warehouse = action.payload;
    },
    setWarehousesSelection: (state, action) => {
      state.warehousesSelection = action.payload;
    },
    setWarehousesParams: (state, action) => {
      state.warehousesParams = action.payload;
    },
    updateMultipleSuppliers: (state, action) => {
      const suppliers = action.payload;
      suppliers.forEach(supplier => {
        const index = state.suppliers.results.findIndex(item => item.id === supplier.id);
        if (index !== -1) {
          state.suppliers.results[index] = { ...state.suppliers.results[index], ...supplier };
        }
      });
    },
    updateMultipleWarehouses: (state, action) => {
      const warehouses = action.payload;
      warehouses.forEach(warehouse => {
        const index = state.warehouses.results.findIndex(item => item.id === warehouse.id);
        if (index !== -1) {
          state.warehouses.results[index] = { ...state.warehouses.results[index], ...warehouse };
        }
      });
    },
    updateSuppliers: (state, action) => {
      const index = state.suppliers.results.findIndex(file => file.id === action.payload.id);
      if (index !== -1) {
        state.suppliers.results[index] = { ...state.suppliers.results[index], ...action.payload };
      }
    },
    updateWarehouse: (state, action) => {
      const index = state.warehouses.results.findIndex(file => file.id === action.payload.id);
      if (index !== -1) {
        state.warehouses.results[index] = { ...state.warehouses.results[index], ...action.payload };
      }
    },
  },
});

export const {
  createMultipleSuppliers,
  createMultipleWarehouses,
  createSuppliers,
  createWarehouses,
  getBottomDifotMetrics,
  getBottomLeadMetrics,
  getDownloadedSuppliers,
  getDownloadedWarehouses,
  getMetricsCriteria,
  getSupplierMetrics,
  getSuppliers,
  getSuppliersFields,
  getTopDifotMetrics,
  getTopLeadMetrics,
  getTopSuppliersSpend,
  getWarehouseBasedWareCosting,
  getWarehouseFields,
  getWarehouses,
  getWarehouseType,
  resetSupplierMetrics,
  resetSuppliers,
  resetSuppliersParams,
  resetSuppliersSelection,
  resetwarehouse,
  resetWarehouseBasedWareCosting,
  resetwarehouses,
  resetWarehousesParams,
  resetWarehousesSelection,
  resetWarehouseType,
  setDifotDateSelectionRange,
  setLeadDateSelectionRange,
  setMetricsDateRangeSelection,
  setSupplierDateSelectionRange,
  setSuppliersParams,
  setSuppliersSelection,
  setWarehouse,
  setWarehousesParams,
  setWarehousesSelection,
  updateMultipleSuppliers,
  updateMultipleWarehouses,
  updateSuppliers,
  updateWarehouse,
  updateWarehousesParams,
} = supplierSlice.actions;

export default supplierSlice.reducer;
