/**
 * FormFieldError
 * Displays an error message for form fields like input, select, or radio buttons.
 *
 * @param {string} message - The error message to be displayed.
 */
const FormFieldError = ({ message }) =>
  message && (
    <div className="fv-plugins-message-container">
      <div className="fv-help-block">
        <span role="alert">{message}</span>
      </div>
    </div>
  );

export { FormFieldError };
