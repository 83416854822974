import clsx from "clsx";
import { useEffect } from "react";
import { useLayout } from "../core";
import { useLocation } from "react-router-dom";
import { DrawerComponent } from "../../assets/ts/components";

const Content = ({ children }) => {
  const { classes } = useLayout();
  const location = useLocation();

  useEffect(() => {
    DrawerComponent.hideAll();
  }, [location]);

  return (
    <div id="kt_content_container" className={clsx(classes.contentContainer.join(" "))}>
      {children}
    </div>
  );
};

export { Content };
