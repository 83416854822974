import React, { memo } from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

import { KTIcon } from "../../../../_metronic/helpers";

const MultipleDocView = memo(({ closeModal, docURL, activeDoc }) => {
  const docs = docURL;

  return (
    <>
      <div
        id="ordlnk_modal_view_document"
        className="modal fade show d-block"
        aria-modal="true"
        role="dialog"
        tabIndex={-1}
      >
        <div className="modal-dialog modal-dialog-centered mw-800px">
          <div className="modal-content">
            <div className="modal-header">
              <div></div>
              <div
                className="btn btn-icon btn-sm btn-active-icon-primary cursor-pointer"
                onClick={() => closeModal()}
              >
                <KTIcon className="fs-1" iconName="cross" />
              </div>
            </div>
            <div className="modal-body doc__modal scroll-y mx-5 my-5">
              <div className="w-100">
                <div className="border rounded-3 shadow-sm">
                  <DocViewer
                    documents={docs}
                    initialActiveDocument={docs[activeDoc]}
                    pluginRenderers={DocViewerRenderers}
                    config={{
                      header: {
                        disableFileName: true,
                        retainURLParams: false,
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </>
  );
});

export { MultipleDocView };
