// C => Confirm
// FC => Flag Completed
// MI => Manual Intervine
// PR => Process
// R => Refresh
// TH => Transaction History
// U => Update
// V => View
// VF => Verify

export const InvoiceMI = "manual_intervine_invoice";
export const InvoiceLineMI = "manual_intervine_invoice_line";
export const InvoiceStatusPR = "process_status_invoice";
export const InvoiceR = "refresh_invoice_docfromemail";
export const InvoiceLineD = "delete_invoiceline";

export const CreateInvoiceDoc = "create_invoice_document";

export const PurchaseOrderVF = "verify_purchaseorder";

export const PurchaseOrderLineETAU = "change_eta_purchaseorderline";
export const PurchaseOrderLineAQU = "change_allocation_qty_purchaseorderline";
export const POLineETAU = "propose_new_eta_to_purchaseorderline";
export const POLineETAC = "confirm_eta_approvalrequest";
export const POLinePRR = "process_reconciliation_receipt_purchaseorderline";
export const POLineV = "view_purchaseorderline";
export const POLateAlert = "view_late_alert_purchaseorder";

export const POReceiptR = "refresh_receiptheader";
export const CreateReceipt = "add_receiptheader";
export const CreateShipmentReceipt = "add_shipment_receiptheader";

export const ReceiptLineItemC = "confirm_po_receipt_approvalrequest";
export const ReceiptLineItemFC = "flag_completed_receiptlineitem";
export const ReceiptLineItemV = "view_receiptlineitem";
export const POLineItemTH = "view_transaction_history_purchaseorderline";
export const SupplierDIFOTMetrics = "view_supplier_difot_metrics_purchaseorder";
export const SupplierLeadTimeMetrics = "view_supplier_leadtime_metrics_purchaseorder";

export const CreateFileDoc = "create_file_document";
export const CreateShipmentDoc = "create_shipment_document";

export const NotificationTypeV = "view_notificationtype";

export const ASN_ADD = "add_asn";
export const ASN_VALIDATE = "asn_validate_consignment";
export const CanReceiveConsignmentItem = "add_consignment_receiptheader";
