import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  purchaseAllocations: { count: 0, results: [] },
  purchaseAllocationsParams: { limit: 10, offset: 0 },

  transferAllocations: { count: 0, results: [] },
  transferAllocationsParams: { limit: 10, offset: 0 },

  transferOrder: null,
  transferOrderBillsParams: { limit: 10, offset: 0 },
  transferOrderFilesParams: { limit: 10, offset: 0 },
  transferOrders: { count: 0, results: [] },
  transferOrdersParams: { limit: 100, offset: 0 },
  transferOrdersSelection: [],
  transferOrderStatus: [],

  transferOrderLine: null,
  transferOrderLines: { count: 0, results: [] },
  transferOrderLinesParams: { limit: 10, offset: 0 },
  transferOrderLineStatus: [],

  toLinesConsolidatePOIds: [],

  purchaseOrderLineProducts: [],
  transferOrderLineProducts: [],

  poLinePurchaseOrders: [],
  toLineTransferOrders: [],
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    addActivityLogToTransferOrder: (state, action) => {
      state.transferOrder = { ...state.transferOrder, ...action.payload };
    },
    addTransferOrderFiles: (state, action) => {
      state.transferOrder = {
        ...state.transferOrder,
        files: {
          count: (state.transferOrder?.files?.count || 0) + action.payload.length,
          results: [...action.payload, ...(state.transferOrder?.files?.results || [])],
        },
      };
    },
    addTransferOrderNote: (state, action) => {
      if (!state.transferOrder) {
        state.transferOrder = {};
      }
      state.transferOrder.notes = [action.payload, ...state.transferOrder.notes];
    },
    editMultiTransferOrderLines: (state, action) => {
      const response = action.payload;
      if (state.transferOrderLines.results.length > 0) {
        response.ids.forEach(item => {
          const index = state.transferOrderLines.results.findIndex(
            order_line => order_line.id === item,
          );

          if (index !== -1) {
            state.transferOrderLines.results[index] = {
              ...state.transferOrderLines.results[index],
              eta: response.eta,
            };
          }
        });
      }

      if (state.transferOrder) {
        response.ids.forEach(item => {
          const index = state.transferOrder.items.findIndex(order_line => order_line.id === item);
          if (index !== -1) {
            state.transferOrder.items[index] = {
              ...state.transferOrder.items[index],
              eta: response.eta,
            };
          }
        });
      }
    },
    editTransferOrder: (state, action) => {
      state.transferOrder = { ...state.transferOrder, ...action.payload };
    },
    getPoLinePurchaseOrders: (state, action) => {
      state.poLinePurchaseOrders = action.payload;
    },
    getPurchaseOrderLineProducts: (state, action) => {
      state.purchaseOrderLineProducts = action.payload;
    },
    getToLineTransferOrders: (state, action) => {
      state.toLineTransferOrders = action.payload;
    },
    getTransferOrderLineProducts: (state, action) => {
      state.transferOrderLineProducts = action.payload;
    },
    getTransferOrderLineStatus: (state, action) => {
      state.transferOrderLineStatus = action.payload;
    },
    removePurchaseAllocations: (state, action) => {
      const { ids } = action.payload;
      state.purchaseAllocations = {
        count: state.purchaseAllocations.count - action.payload.ids.length,
        results: state.purchaseAllocations.results.filter(item => !ids.includes(item.id)),
      };
    },
    removeTransferAllocations: (state, action) => {
      const { ids } = action.payload;
      state.transferAllocations = {
        count: state.transferAllocations.count - action.payload.ids.length,
        results: state.transferAllocations.results.filter(item => !ids.includes(item.id)),
      };
    },
    resetPurchaseAllocations: state => {
      state.purchaseAllocations = initialState.purchaseAllocations;
    },
    resetPurchaseAllocationsParams: state => {
      state.purchaseAllocationsParams = { limit: state.purchaseAllocationsParams.limit, offset: 0 };
    },
    resetTOLinesConsolidatePOIds: (state, action) => {
      state.toLinesConsolidatePOIds = initialState.toLinesConsolidatePOIds;
    },
    resetTransferAllocations: state => {
      state.transferAllocations = initialState.transferAllocations;
    },
    resetTransferAllocationsParams: state => {
      state.transferAllocationsParams = { limit: state.transferAllocationsParams.limit, offset: 0 };
    },
    resetTransferOrder: state => {
      state.transferOrder = initialState.transferOrder;
    },
    resetTransferOrderLine: state => {
      state.transferOrderLine = initialState.transferOrderLine;
    },
    resetTransferOrderLines: state => {
      state.transferOrderLines = initialState.transferOrderLines;
    },
    resetTransferOrderLinesParams: state => {
      state.transferOrderLinesParams = { limit: state.transferOrderLinesParams.limit, offset: 0 };
    },
    resetTransferOrders: state => {
      state.transferOrders = initialState.transferOrders;
    },
    resetTransferOrdersParams: state => {
      state.transferOrdersParams = { limit: state.transferOrdersParams.limit, offset: 0 };
    },
    resetTransferOrderStatus: (state, action) => {
      state.transferOrderStatus = initialState.transferOrderStatus;
    },
    setPurchaseAllocations: (state, action) => {
      const { count, results } = action.payload;
      state.purchaseAllocations = { count, results };
    },
    setPurchaseAllocationsParams: (state, action) => {
      state.purchaseAllocationsParams = action.payload;
    },
    setTOLinesConsolidatePOIds: (state, action) => {
      state.toLinesConsolidatePOIds = action.payload;
    },
    setTransferAllocations: (state, action) => {
      const { count, results } = action.payload;
      state.transferAllocations = { count, results };
    },
    setTransferAllocationsParams: (state, action) => {
      state.transferAllocationsParams = action.payload;
    },
    setTransferOrder: (state, action) => {
      state.transferOrder = action.payload;
    },
    setTransferOrderBills: (state, action) => {
      if (!state.transferOrder) {
        state.transferOrder = {};
      }
      state.transferOrder.bills = { count: action.payload.count, results: action.payload.results };
    },
    setTransferOrderBillsParams: (state, action) => {
      state.transferOrderBillsParams = action.payload;
    },
    setTransferOrderFiles: (state, action) => {
      // Initialize transferOrder if it doesn't exist
      if (!state.transferOrder) {
        state.transferOrder = {};
      }

      state.transferOrder.files = {
        count: action.payload.count,
        results: action.payload.results,
      };
    },
    setTransferOrderFilesParams: (state, action) => {
      state.transferOrderFilesParams = action.payload;
    },
    setTransferOrderNotes: (state, action) => {
      if (!state.transferOrder) {
        state.transferOrder = {};
      }
      state.transferOrder.notes = action.payload;
    },
    setTransferOrderLine: (state, action) => {
      state.transferOrderLine = action.payload;
    },
    setTransferOrderLines: (state, action) => {
      const { count, results } = action.payload;
      state.transferOrderLines = { count, results };
    },
    setTransferOrderLinesParams: (state, action) => {
      state.transferOrderLinesParams = action.payload;
    },
    setTransferOrders: (state, action) => {
      const { count, results } = action.payload;
      state.transferOrders = { count, results };
    },
    setTransferOrdersParams: (state, action) => {
      state.transferOrdersParams = action.payload;
    },
    setTransferOrdersSelection: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.transferOrdersSelection = action.payload;
      }
    },
    setTransferOrderStatus: (state, action) => {
      state.transferOrderStatus = action.payload;
    },
    updateAllocationsAfterAddToShipment: (state, action) => {
      const { po_line_ids, tf_ord_line_ids } = action.payload;

      if (po_line_ids) {
        state.purchaseAllocations = {
          ...state.purchaseAllocations,
          count: state.purchaseAllocations.count - po_line_ids.length,
          results: state.purchaseAllocations.results.filter(item => !po_line_ids.includes(item.id)),
        };
      } else if (tf_ord_line_ids) {
        state.transferAllocations = {
          ...state.transferAllocations,
          count: state.transferAllocations.count - tf_ord_line_ids.length,
          results: state.transferAllocations.results.filter(
            item => !tf_ord_line_ids.includes(item.id),
          ),
        };
      }
    },
    updateMultiTFOrderLineReceiptedQty: (state, action) => {
      const tf_ord_lines = action.payload;
      tf_ord_lines.forEach(tf_ord_line => {
        if (state.transferOrder) {
          const index = state.transferOrder.items.findIndex(item => item.id === tf_ord_line.id);
          if (index !== -1) {
            state.transferOrder.items[index] = {
              ...state.transferOrder.items[index],
              ...tf_ord_line,
            };
          }
        }
        if (state.transferOrderLines) {
          const TFOrderLineIndex = state.transferOrderLines.results.findIndex(
            item => item.id === tf_ord_line.id,
          );
          if (TFOrderLineIndex !== -1) {
            state.transferOrderLines.results[TFOrderLineIndex] = {
              ...state.transferOrderLines.results[TFOrderLineIndex],
              ...tf_ord_line,
            };
          }
        }
      });
    },
    updatePurchaseAllocatedQty: (state, action) => {
      const { id, ...rest } = action.payload;
      const index = state.purchaseAllocations.results.findIndex(item => item.id === id);
      if (index !== -1) {
        state.purchaseAllocations.results[index] = {
          ...state.purchaseAllocations.results[index],
          ...rest,
        };
      }
    },
    updatePurchaseAllocationsParams: (state, action) => {
      state.purchaseAllocationsParams = { ...state.purchaseAllocationsParams, ...action.payload };
    },
    updatePurchaseOrderAllocationStatus: (state, action) => {
      const { ids, status } = action.payload;
      ids.forEach(id => {
        const index = state.purchaseAllocations.results.findIndex(
          purchaseOrder => purchaseOrder.id === id,
        );
        if (index !== -1) {
          state.purchaseAllocations.results[index] = {
            ...state.purchaseAllocations.results[index],
            status,
          };
        }
      });
    },
    updateTransferAllocatedQty: (state, action) => {
      const { allocatable, allocated_qty, id, product_plan, status } = action.payload;
      const allocationIndex = state.transferAllocations.results.findIndex(item => item.id === id);
      if (allocationIndex !== -1) {
        const allocation = state.transferAllocations.results[allocationIndex];
        state.transferAllocations.results[allocationIndex] = {
          ...allocation,
          allocatable,
          allocated_qty,
          status,
        };
        state.transferAllocations.results.forEach((item, index) => {
          if (item.id !== id && item.product_plan === product_plan) {
            state.transferAllocations.results[index] = { ...item, allocatable };
          }
        });
      }
    },
    updateTransferAllocationsParams: (state, action) => {
      state.transferAllocationsParams = { ...state.transferAllocationsParams, ...action.payload };
    },
    updateTransferOrderAllocationStatus: (state, action) => {
      const { ids, status } = action.payload;
      ids.forEach(id => {
        const index = state.transferAllocations.results.findIndex(
          transferOrder => transferOrder.id === id,
        );
        if (index !== -1) {
          state.transferAllocations.results[index] = {
            ...state.transferAllocations.results[index],
            status,
          };
        }
      });
    },
    updateTransferOrderFiles: (state, action) => {
      state.transferOrder = state.transferOrder || {};
      state.transferOrder.files = state.transferOrder.files || { count: 0, results: [] };
      state.transferOrder.files = { ...state.transferOrder.files, ...action.payload };
    },
    updateTransferOrderLines: (state, action) => {
      const { id, name, tf_ord_lines } = action.payload;
      const idSet = new Set(tf_ord_lines);
      state.transferOrderLines.results.forEach(transferOrderLine => {
        if (idSet.has(transferOrderLine.id)) {
          transferOrderLine.po_line = { id, name };
        }
      });
    },
    updateTransferOrderLinesParams: (state, action) => {
      state.transferOrderLinesParams = { ...state.transferOrderLinesParams, ...action.payload };
    },
    updateTransferOrderLineStatus: (state, action) => {
      const { ids, ...rest } = action.payload;
      ids.forEach(id => {
        const index = state.transferOrderLines.results.findIndex(
          transferOrder => transferOrder.id === id,
        );
        if (index !== -1) {
          state.transferOrderLines.results[index] = {
            ...state.transferOrderLines.results[index],
            ...rest,
          };
        }
      });
    },
  },
});

export const {
  addActivityLogToTransferOrder,
  addTransferOrderFiles,
  addTransferOrderNote,
  editMultiTransferOrderLines,
  editTransferOrder,
  getPoLinePurchaseOrders,
  getPurchaseOrderLineProducts,
  getToLineTransferOrders,
  getTransferOrderLineProducts,
  getTransferOrderLineStatus,
  removePurchaseAllocations,
  removeTransferAllocations,
  resetPurchaseAllocations,
  resetPurchaseAllocationsParams,
  resetTOLinesConsolidatePOIds,
  resetTransferAllocations,
  resetTransferAllocationsParams,
  resetTransferOrder,
  resetTransferOrderLine,
  resetTransferOrderLines,
  resetTransferOrderLinesParams,
  resetTransferOrders,
  resetTransferOrderStatus,
  setPurchaseAllocations,
  setPurchaseAllocationsParams,
  setTOLinesConsolidatePOIds,
  setTransferAllocations,
  setTransferAllocationsParams,
  setTransferOrder,
  setTransferOrderBills,
  setTransferOrderBillsParams,
  setTransferOrderFiles,
  setTransferOrderFilesParams,
  setTransferOrderLine,
  setTransferOrderLines,
  setTransferOrderLinesParams,
  setTransferOrderNotes,
  setTransferOrders,
  setTransferOrdersParams,
  setTransferOrdersSelection,
  setTransferOrderStatus,
  updateAllocationsAfterAddToShipment,
  updateMultiTFOrderLineReceiptedQty,
  updatePurchaseAllocatedQty,
  updatePurchaseAllocationsParams,
  updatePurchaseOrderAllocationStatus,
  updateTransferAllocatedQty,
  updateTransferAllocationsParams,
  updateTransferOrderAllocationStatus,
  updateTransferOrderFiles,
  updateTransferOrderLines,
  updateTransferOrderLinesParams,
  updateTransferOrderLineStatus,
} = orderSlice.actions;

export default orderSlice.reducer;
