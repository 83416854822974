import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { ThemeModeProvider } from "../_metronic/partials";
import { MasterInit } from "../_metronic/layout/MasterInit";
import { I18nProvider } from "../_metronic/i18n/i18nProvider";
import { LayoutProvider, LayoutSplashScreen } from "../_metronic/layout/core";
import { setNotification } from "../store/slice/miscSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

const App = () => {
  const dispatch = useDispatch();

  const notification = useSelector(state => state.misc.notification);

  useEffect(() => {
    // Ensure notification object exists and has a type property
    if (notification && notification.type && toast[notification.type]) {
      toast[notification.type](notification.message, {
        autoClose: 3000,
        closeOnClick: true,
        draggable: true,
        hideProgressBar: false,
        pauseOnHover: true,
        position: "top-right",
        theme: "colored",
      });
      dispatch(setNotification(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification]);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <ThemeModeProvider>
            <ToastContainer />
            <Outlet />
            <MasterInit />
          </ThemeModeProvider>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  );
};

export { App };
