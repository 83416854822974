import { setNotification } from "../store/slice/miscSlice";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

/**
 * Custom hook to dispatch notifications.
 * @param {Function} dispatch - The dispatch function from your state management system.
 * @returns {Function} - A callback function that dispatches notifications.
 */
export const useNotification = () => {
  const dispatch = useDispatch();
  const notify = useCallback(
    ({ type, message }) => dispatch(setNotification({ type: type, message: message })),
    [dispatch],
  );

  return notify;
};
