/* eslint-disable no-unused-vars */

import { DefaultLayoutConfig } from "./DefaultLayoutConfig";

const LAYOUT_CONFIG_KEY = process.env.REACT_APP_BASE_LAYOUT_CONFIG_KEY || "LayoutConfig";

export function getLayout() {
  const ls = localStorage.getItem(LAYOUT_CONFIG_KEY);
  if (ls) {
    try {
      return JSON.parse(ls);
    } catch (error) {
      console.error(error);
    }
  }
  return DefaultLayoutConfig;
}

function setLayout(config) {
  try {
    localStorage.setItem(LAYOUT_CONFIG_KEY, JSON.stringify(config));
  } catch (error) {
    console.error(error);
  }
}

export function getEmptyCssClasses() {
  return {
    header: [],
    headerContainer: [],
    headerMobile: [],
    headerMenu: [],
    aside: [],
    asideMenu: [],
    asideToggle: [],
    toolbar: [],
    toolbarContainer: [],
    content: [],
    contentContainer: [],
    footerContainer: [],
    sidebar: [],
    pageTitle: [],
  };
}

export function getEmptyHTMLAttributes() {
  return {
    asideMenu: new Map(),
    headerMobile: new Map(),
    headerMenu: new Map(),
    headerContainer: new Map(),
    pageTitle: new Map(),
  };
}

export function getEmptyCSSVariables() {
  return {
    body: new Map(),
  };
}

export class LayoutSetup {
  static isLoaded = false;
  static config = getLayout();
  static classes = getEmptyCssClasses();
  static attributes = getEmptyHTMLAttributes();
  static cssVariables = getEmptyCSSVariables();

  static initCSSClasses() {
    LayoutSetup.classes = getEmptyCssClasses();
  }

  static initHTMLAttributes() {
    LayoutSetup.attributes = { ...getEmptyHTMLAttributes() };
  }

  static initCSSVariables() {
    LayoutSetup.cssVariables = { ...getEmptyCSSVariables() };
  }

  static initLayout(config) {
    Array.from(document.body.attributes).forEach(attr => {
      document.body.removeAttribute(attr.name);
    });
    document.body.setAttribute("style", "");
    document.body.setAttribute("id", "kt_body");
    if (config.main?.body?.backgroundImage) {
      document.body.style.backgroundImage = `url('${config.main.body.backgroundImage}')`;
    }

    if (config.main?.body?.class) {
      document.body.classList.add(config.main.body.class);
    }
  }

  static initHeader(config) {
    LayoutSetup.classes.headerContainer.push(
      config.width === "fluid" ? "container-fluid" : "container",
    );

    if (config.fixed.tabletAndMobile) {
      document.body.classList.add("header-tablet-and-mobile-fixed");
    }
  }

  static initToolbar(config) {
    if (!config.display) {
      return;
    }

    document.body.classList.add("toolbar-enabled");
    LayoutSetup.classes.toolbarContainer.push(
      config.width === "fluid" ? "container-fluid" : "container",
    );

    if (config.fixed.desktop) {
      document.body.classList.add("toolbar-fixed");
    }

    if (config.fixed.tabletAndMobileMode) {
      document.body.classList.add("toolbar-tablet-and-mobile-fixed");
    }

    const type = config.layout;
    const typeOptions = config.layouts[type];
    if (typeOptions) {
      let bodyStyles = "";
      if (typeOptions.height) {
        bodyStyles += ` --bs-toolbar-height: ${typeOptions.height};`;
      }

      if (typeOptions.heightAndTabletMobileMode) {
        bodyStyles += ` --bs-toolbar-height-tablet-and-mobile: ${typeOptions.heightAndTabletMobileMode};`;
      }
      document.body.setAttribute("style", bodyStyles);
    }
  }

  static initContent(config) {
    LayoutSetup.classes.contentContainer.push(
      config.width === "fluid" ? "container-fluid" : "container",
    );
  }

  static initAside(config) {
    document.body.classList.add("aside-enabled");
    if (config.fixed) {
      document.body.classList.add("aside-fixed");
    }

    if (config.minimized) {
      document.body.setAttribute("data-kt-aside-minimize", "on");
    }

    if (config.hoverable) {
      LayoutSetup.classes.aside.push("aside-hoverable");
    }
  }

  static initAsideMenu(config) {
    // Implement your code for initializing aside menu
  }

  static initFooter(config) {
    LayoutSetup.classes.footerContainer.push(
      `container${config.width === "fluid" ? "-fluid" : ""}`,
    );
  }

  static initConfig(config) {
    if (config.main?.darkSkinEnabled) {
      document.body.classList.add("dark-skin");
    }

    LayoutSetup.initLayout(config);
    if (config.main?.type !== "default") {
      return;
    }

    LayoutSetup.initHeader(config.header);
    LayoutSetup.initContent(config.content);
    LayoutSetup.initAside(config.aside);
    LayoutSetup.initFooter(config.footer);
    LayoutSetup.initAsideMenu(config.aside);
  }

  static updatePartialConfig(fieldsToUpdate) {
    const config = LayoutSetup.config;
    const updatedConfig = { ...config, ...fieldsToUpdate };
    document.body.className = "";
    LayoutSetup.initCSSClasses();
    LayoutSetup.initCSSVariables();
    LayoutSetup.initHTMLAttributes();
    LayoutSetup.isLoaded = false;
    LayoutSetup.config = updatedConfig;
    LayoutSetup.initConfig({ ...updatedConfig });
    LayoutSetup.isLoaded = true;
    return updatedConfig;
  }

  static setConfig(config) {
    setLayout(config);
  }

  static bootstrap = (() => {
    LayoutSetup.updatePartialConfig(LayoutSetup.config);
  })();
}
