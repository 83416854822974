import * as Yup from "yup";
import { FormFieldError } from "../../../../components/atoms/FormFiledError";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import {
  useCreateDocReplacementMutation,
  useLazyValidateDocTokenQuery,
} from "../../../../store/apiSlice/fileApiSlice";
import { useNotification } from "../../../../hooks/useNotification";
import { consolidateErrors } from "../../../../helper/utils";
import { MultipleDocView } from "./MultipleDocView";

const validationSchema = Yup.object({
  // Validation rules for each form field
  files: Yup.array()
    .of(
      Yup.mixed().test(
        "fileType",
        "Unsupported file format. Please upload PDF, JPEG, PNG, or WEBP types of files.",
        file => {
          const supportedFormats = ["application/pdf", "image/jpeg", "image/png", "image/webp"];
          return file && supportedFormats.includes(file.type);
        },
      ),
    )
    .required("At least one file is required.")
    .min(1, "At least one file is required."),
});

const Upload = () => {
  const [reminderFiles, setReminderFiles] = useState([]);
  const { token } = useParams();
  const [docUrls, setDocUrls] = useState([]);
  const [activeDoc, setActiveDoc] = useState("");

  const navigate = useNavigate();
  const notify = useNotification();

  const [showModal, setShowModal] = useState(false);
  const openModal = docClicked => {
    setShowModal(true);
    setActiveDoc(docClicked);
  };
  const closeModal = () => setShowModal(false);

  const [validateToken, { isLoading }] = useLazyValidateDocTokenQuery();

  const [createDocReplacement] = useCreateDocReplacementMutation();

  const tokenValidation = async () => {
    try {
      const response = await validateToken(token).unwrap();
      setReminderFiles(response.files);
    } catch (error) {
      notify({ type: "error", message: error.data?.detail || "Something went wrong" });
      navigate("/");
    }
  };

  useEffect(() => {
    if (token) {
      tokenValidation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const formik = useFormik({
    initialValues: { files: [] },
    validationSchema: validationSchema,
    onSubmit: async values => {
      try {
        // Prepare form data
        const formData = new FormData();
        Array.from(values.files).forEach(file => {
          formData.append("files", file);
        });
        formData.append("token", token);

        const data = await createDocReplacement(formData).unwrap();
        notify({ type: "success", message: data.detail });
        navigate("/");
      } catch (error) {
        const err = error?.data;
        if (err.detail) {
          notify({ type: "error", message: err.detail });
        } else {
          const errs = Array.isArray(err) ? consolidateErrors(err) : err;
          // Handle form submission error
          formik.setErrors(errs);
        }
      }
    },
  });

  useEffect(() => {
    let fileUrls = [];
    reminderFiles.forEach(file => {
      fileUrls.push({
        uri: file.file,
      });
    });
    setDocUrls(fileUrls);
  }, [reminderFiles]);

  const DocumentView = useMemo(
    () => <MultipleDocView closeModal={closeModal} docURL={docUrls} activeDoc={activeDoc} />,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showModal],
  );

  if (!token) return <Navigate to="/" />;

  return (
    !isLoading && (
      <div className="d-flex flex-column flex-lg-row flex-column-fluid h-100">
        <div className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-2 order-lg-1">
          <div className="d-flex flex-column flex-center py-15 px-5 px-md-15 w-100">
            <Link to="/" className="mt-25">
              <img
                alt="Orderlink Logo"
                src={toAbsoluteUrl("/media/orderlink_logos/blue.png")}
                className="h-50px"
              />
            </Link>
            <span>
              <h4 className="mb-4">
                You can click here to view your certificates: <br />
              </h4>
              <ol>
                {reminderFiles.map((file, index) => (
                  <li key={index} className="fs-6 fw-medium text-gray">
                    <h3>
                      <span
                        className="cursor-pointer text-primary"
                        onClick={() => openModal(index)}
                      >
                        {file.certificate_type || file.document_type}
                      </span>
                      .
                    </h3>
                  </li>
                ))}
              </ol>
            </span>
          </div>
        </div>
        {showModal && DocumentView}

        <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 py-10 order-1">
          <div className="d-flex flex-center flex-column flex-lg-row-fluid">
            <form
              className="form fv-plugins-bootstrap5 fv-plugins-framework w-75 w-lg-50 py-10"
              encType="multipart/form-data"
              onSubmit={formik.handleSubmit}
            >
              <div className="text-center mb-11">
                <h1 className="text-dark fw-bolder mb-3">Upload File</h1>
              </div>

              {/* Multiple Upload File Input */}
              <div className="row mb-8">
                <label className="fs-6 fw-medium text-gray mb-2 required" htmlFor="files-input">
                  Upload File
                </label>
                <div className="col fv-row">
                  <input
                    id="files-input"
                    className="form-control bg-transparent mb-3 mb-lg-0"
                    multiple={true}
                    name="files"
                    type="file"
                    onChange={event => {
                      formik.setFieldValue("files", Array.from(event.currentTarget.files));
                    }}
                  />
                  <FormFieldError message={formik.touched.files && formik.errors.files} />
                </div>
              </div>

              {/* Submit Button */}
              <div className="text-center">
                <button
                  className="btn btn-primary"
                  disabled={
                    formik.isSubmitting ||
                    !formik.isValid ||
                    !formik.touched ||
                    !formik.dirty ||
                    isLoading
                  }
                  type="submit"
                >
                  {formik.isSubmitting || isLoading ? (
                    <span className="d-block indicator-progress">
                      Please wait...
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  ) : (
                    <span className="indicator-label">Submit</span>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  );
};

export { Upload };
