import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  asn: null,
  asnList: { count: 0, result: 10 },
  asnListParams: { limit: 10, offset: 0 },

  consignment: null,
  consignmentBillsParams: { limit: 10, offset: 0 },
  consignmentFilesParams: { limit: 10, offset: 0 },
  consignments: { count: 0, results: [] },
  consignmentsParams: { limit: 10, offset: 0 },
  consignmentsSelection: [],

  consignmentItems: { count: 0, results: [] },
  consignmentItemsParams: { limit: 10, offset: 0 },
  consignmentItemsStatus: [],

  shipment: null,
  shipments: { count: 0, results: [] },
  shipmentsParams: { limit: 10, offset: 0 },
};

const shipmentSlice = createSlice({
  name: "shipment",
  initialState,
  reducers: {
    addConsignmentFiles: (state, action) => {
      state.consignment = {
        ...state.consignment,
        files: {
          count: (state.consignment?.files?.count || 0) + action.payload.length,
          results: [...action.payload, ...(state.consignment?.files?.results || [])],
        },
      };
    },
    addShipment: (state, action) => {
      state.shipments = {
        ...state.shipments,
        count: state.shipments.count + 1,
        results: [action.payload, ...state.shipments.results],
      };
    },
    addConsignmentNote: (state, action) => {
      if (!state.consignment) {
        state.consignment = {};
      }
      state.consignment.notes = [action.payload, ...state.consignment.notes];
    },
    editConsignment: (state, action) => {
      state.consignment = { ...state.consignment, ...action.payload };
    },
    editConsignmentItem: (state, action) => {
      action.payload.forEach(item => {
        const { id, ...rest } = item;
        const index = state.consignment.items.findIndex(obj => obj.id === id);
        if (index !== -1) {
          state.consignment.items[index] = { ...state.consignment.items[index], ...rest };
        }
      });
    },
    mutateConsignmentItem: (state, action) => {
      action.payload.forEach(item => {
        const index = state.consignment.items.findIndex(obj => obj.id === item.id);
        if (index !== -1) {
          state.consignment.items[index] = {
            ...state.consignment.items[index],
            received_qty: (
              Number(state.consignment.items[index].received_qty) + Number(item.received_qty)
            ).toFixed(4),
          };
        }
      });
      if (action.payload.length > 0) {
        state.consignment.status = action.payload[0].status;
      }
    },
    mutateShipmentLine: (state, action) => {
      action.payload.forEach(item => {
        const index = state.shipment.shipment_lines.findIndex(obj => obj.id === item.id);
        if (index !== -1) {
          state.shipment.shipment_lines[index] = {
            ...state.shipment.shipment_lines[index],
            received_qty: (
              Number(state.shipment.shipment_lines[index].received_qty) + Number(item.received_qty)
            ).toFixed(4),
          };
        }
      });
    },
    resetASN: state => {
      state.asn = initialState.asn;
    },
    resetASNList: state => {
      state.asnList = initialState.asnList;
    },
    resetASNListParams: state => {
      state.asnListParams = { limit: state.asnListParams.limit, offset: 0 };
    },
    resetConsignment: state => {
      state.consignment = initialState.consignment;
    },
    resetConsignmentItems: state => {
      state.consignmentItems = initialState.consignmentItems;
    },
    resetConsignmentItemsParams: state => {
      state.consignmentItemsParams = { limit: state.consignmentItemsParams.limit, offset: 0 };
    },
    resetConsignments: state => {
      state.consignments = initialState.consignments;
    },
    resetConsignmentsParams: state => {
      state.consignmentsParams = { limit: state.consignmentsParams.limit, offset: 0 };
    },
    resetConsignmentsSelection: (state, action) => {
      state.consignmentsSelection = initialState.consignmentsSelection;
    },
    resetShipment: state => {
      state.shipment = null;
    },
    resetShipments: state => {
      state.shipments = { count: 0, results: [] };
    },
    resetShipmentsParams: state => {
      state.shipmentsParams = { limit: state.shipmentsParams.limit, offset: 0 };
    },
    setASN: (state, action) => {
      state.asn = action.payload;
    },
    setASNList: (state, action) => {
      state.asnList = action.payload;
    },
    setASNListParams: (state, action) => {
      state.asnListParams = action.payload;
    },
    setCancelConsignments: (state, action) => {
      const { ids } = action.payload;
      const idSet = new Set(ids);
      state.consignments.results.forEach(consignment => {
        if (idSet.has(consignment.id)) {
          consignment.status = "Cancelled";
        }
      });
    },
    setConsignment: (state, action) => {
      state.consignment = action.payload;
    },
    setConsignmentBills: (state, action) => {
      if (!state.consignment) {
        state.consignment = {};
      }
      state.consignment.bills = { count: action.payload.count, results: action.payload.results };
    },
    setConsignmentBillsParams: (state, action) => {
      state.consignmentBillsParams = action.payload;
    },
    setConsignmentFiles: (state, action) => {
      // Initialize consignment if it doesn't exist
      if (!state.consignment) {
        state.consignment = {};
      }

      state.consignment.files = {
        count: action.payload.count,
        results: action.payload.results,
      };
    },
    setConsignmentFilesParams: (state, action) => {
      state.consignmentFilesParams = action.payload;
    },
    setConsignmentItems: (state, action) => {
      state.consignmentItems = action.payload;
    },
    setConsignmentItemsParams: (state, action) => {
      state.consignmentItemsParams = action.payload;
    },
    setConsignmentItemsStatus: (state, action) => {
      state.consignmentItemsStatus = action.payload;
    },
    setConsignmentNotes: (state, action) => {
      if (!state.consignment) {
        state.consignment = {};
      }
      state.consignment.notes = action.payload;
    },
    setConsignments: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.consignmentsSelection = action.payload;
      } else {
        state.consignments = action.payload;
      }
    },
    setConsignmentsParams: (state, action) => {
      state.consignmentsParams = action.payload;
    },
    setConsignmentsSelection: (state, action) => {
      state.consignmentsSelection = action.payload;
    },
    setShipment: (state, action) => {
      state.shipment = action.payload;
    },
    setShipments: (state, action) => {
      state.shipments = action.payload;
    },
    setShipmentsParams: (state, action) => {
      state.shipmentsParams = { ...action.payload };
    },
    updateASNListParams: (state, action) => {
      state.asnListParams = { ...state.asnListParams, ...action.payload };
    },
    updateConsignment: (state, action) => {
      state.consignment = { ...state.consignment, ...action.payload };
    },
    updateConsignmentFiles: (state, action) => {
      state.consignment = state.consignment || {};
      state.consignment.files = state.consignment.files || { count: 0, results: [] };
      state.consignment.files = { ...state.consignment.files, ...action.payload };
    },
    updateConsignmentForASNValidation: (state, action) => {
      const data = action.payload;
      data.forEach(item => {
        const index = state.consignment.items.findIndex(obj => obj.id === item.id);
        if (index !== -1) {
          state.consignment.items[index] = { ...state.consignment.items[index], ...item };
        }
      });
      state.consignment.can_create_asn = state.consignment.items.every(item =>
        item.asn_validation.every(obj => obj.is_valid),
      );
    },
    updateConsignmentsParams: (state, action) => {
      state.consignmentsParams = { ...state.consignmentsParams, ...action.payload };
    },
    updateShipment: (state, action) => {
      state.shipment = { ...state.shipment, ...action.payload };
    },
    updateShipmentLine: (state, action) => {
      const shipmentLineIndex = state.shipment.shipment_lines.findIndex(
        item => item.id === action.payload.id,
      );
      if (shipmentLineIndex !== -1) {
        state.shipment.shipment_lines[shipmentLineIndex] = action.payload;
      }
      state.shipment.supplier = action.payload.supplier;
    },
    updateShipmentsParams: (state, action) => {
      state.shipmentsParams = { ...state.shipmentsParams, ...action.payload };
    },
    updateTrackingInfo: (state, action) => {
      action.payload.forEach(item => {
        const { id, ...rest } = item;
        const index = state.consignment.items.findIndex(obj => obj.id === id);
        if (index !== -1) {
          state.consignment.items[index] = { ...state.consignment.items[index], ...rest };
        }
      });
    },
  },
});

export const {
  addConsignmentFiles,
  addConsignmentNote,
  addShipment,
  editConsignment,
  editConsignmentItem,
  mutateConsignmentItem,
  mutateShipmentLine,
  resetASN,
  resetASNList,
  resetASNListParams,
  resetConsignment,
  resetConsignmentItems,
  resetConsignmentItemsParams,
  resetConsignments,
  resetConsignmentsParams,
  resetConsignmentsSelection,
  resetShipment,
  resetShipments,
  resetShipmentsParams,
  setASN,
  setASNList,
  setASNListParams,
  setCancelConsignments,
  setConsignment,
  setConsignmentBills,
  setConsignmentBillsParams,
  setConsignmentFiles,
  setConsignmentFilesParams,
  setConsignmentItems,
  setConsignmentItemsParams,
  setConsignmentItemsStatus,
  setConsignmentNotes,
  setConsignments,
  setConsignmentsParams,
  setConsignmentsSelection,
  setShipment,
  setShipments,
  setShipmentsParams,
  updateASNListParams,
  updateConsignment,
  updateConsignmentFiles,
  updateConsignmentForASNValidation,
  updateConsignmentsParams,
  updateShipment,
  updateShipmentLine,
  updateShipmentsParams,
  updateTrackingInfo,
} = shipmentSlice.actions;

export default shipmentSlice.reducer;
