import React, { useState } from "react";

import { KTIcon } from "../../_metronic/helpers";
import { OpenModalViewProvider, useModalView } from "./Provider/Open";
import { CreatePurchaseOrderAction } from "./components/PurchaseOrderAction";
import { GlobalListModals } from "./Modal";

const GlobalMenu = () => {
  const path = "#";
  return (
    <>
      <OpenModalViewProvider id="open_view">
        <div>
          <a
            className="btn btn-sm btn-icon btn-icon-muted btn-active-icon-primary"
            data-kt-menu-attach="parent"
            data-kt-menu-placement="bottom-end"
            data-kt-menu-trigger="click"
            href={path}
          >
            <div className="position-relative">
              <KTIcon className="fs-1" iconName="abstract-10" />
            </div>
          </a>
          <GlobalMenuItems />
        </div>

        <GlobalListModals />
      </OpenModalViewProvider>
    </>
  );
};

const GlobalMenuItems = () => {
  const id = "createPurchaseOrderAction";
  const [poAction, setPOAction] = useState(false);
  const hadlePOAction = () => setPOAction(!poAction);

  const { setCurrentAction } = useModalView();

  return (
    <>
      <div
        id="global-menu"
        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
        data-kt-menu="true"
        data-popper-placement="bottom-start"
      >
        <div className="menu-item">
          <span className="menu-link px-5 py-1" onClick={() => setCurrentAction(id)}>
            Create Purchase Order
          </span>
          {/* <div className="separator my-2"></div> */}
        </div>
      </div>{" "}
      {poAction && <CreatePurchaseOrderAction closeActionModal={hadlePOAction} />}
    </>
  );
};

export default GlobalMenu;
