import clsx from "clsx";
import { Link } from "react-router-dom";
import { KTIcon, toAbsoluteUrl } from "../../../helpers";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLogoutMutation } from "../../../../store/apiSlice/authApiSlice";
import { logOutUser } from "../../../../store/slice/utils";

const AsideMenuModal = ({ currentUser, children, title, icon, fontIcon, hasBullet = false }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="menu-item">
        <Link className={clsx("menu-link without-sub")} to="#" onClick={handleShow}>
          {hasBullet && (
            <span className="menu-bullet">
              <span className="bullet bullet-dot"></span>
            </span>
          )}
          {icon && (
            <span className="menu-icon">
              <KTIcon iconName={icon} className="fs-2" />
            </span>
          )}
          {fontIcon && <i className={clsx("bi fs-3", fontIcon)}></i>}
          <span className="menu-title">{title}</span>
        </Link>
        {children}
      </div>

      {show && <AsideMenuModalBody currentUser={currentUser} handleClose={handleClose} />}
    </>
  );
};

const AsideMenuModalBody = ({ currentUser, handleClose, searchValue = "Published Manually" }) => {
  const [logout, { isLoading, isSuccess }] = useLogoutMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isSuccess) {
      dispatch(logOutUser());
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const doLogout = async () => {
    await logout();
  };

  return (
    <>
      <div className="fade modal-backdrop show"></div>
      <div
        aria-modal="true"
        className="modal fade show d-block "
        id="publishModal"
        role="dialog"
        tabIndex={-1}
      >
        <div className="modal-dialog  my-15">
          <div className="modal-content user_info_modal">
            <div className="modal-header">
              <h2 className="fw-bolder ">Account</h2>
              <div
                className="btn btn-icon btn-sm btn-active-icon-primary"
                style={{ cursor: "pointer" }}
                onClick={handleClose}
              >
                <KTIcon className="fs-1" iconName="cross" />
              </div>
            </div>
            <div className="modal-body">
              <div className="d-flex flex-wrap flex-sm-nowrap">
                <div className="me-7 mb-2">
                  <div className="symbol symbol-55px symbol-lg-55px symbol-fixed position-relative">
                    <img src={toAbsoluteUrl("/media/avatars/blank.png")} alt="Pic" />
                  </div>
                </div>
                <div className="flex-grow-1 d-flex justify-content-between flex-row">
                  <div className="d-flex justify-content-between flex-column gap-5 align-items-start flex-wrap mb-2">
                    <div className="d-flex flex-column">
                      <div className="d-flex align-items-center mb-2">
                        <div className="fs-2 fw-bolder me-1 text-break">
                          {currentUser?.full_name}
                        </div>
                      </div>
                      <div className="d-flex flex-wrap flex-column gap-2 fw-bold fs-6 pe-2">
                        <div className="d-flex align-items-center me-5 mb-2">
                          <KTIcon iconName="user" className="fs-4 me-1" />
                          {currentUser?.groups[0]?.name || "Superuser"}
                        </div>
                        <div className="d-flex align-items-center me-5 mb-2">
                          <KTIcon iconName="sms" className="fs-4 me-1" />
                          {currentUser?.email}
                        </div>
                        <div className="d-flex align-items-center mb-2">
                          <KTIcon iconName="office-bag" className="fs-4 me-1" />
                          {currentUser?.company || currentUser?.supplier?.name || "Orderlink"}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between flex-column gap-5 align-items-start flex-wrap mb-2 min-w-100px">
                    <div className="flex flex-column">
                      <div className="d-flex flex-wrap flex-column gap-2 fw-bold fs-6 pe-2">
                        <div className="d-flex align-items-center me-5 mb-2">Go to</div>
                        <div className="d-flex align-items-center me-5 mb-2" onClick={handleClose}>
                          <Link className="menu-link  text-hover-primary" to="/account">
                            Account
                            <KTIcon iconName="arrow-up-right" className="fs-4 ms-1" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              {isLoading && (
                <span className="spinner-border spinner-border-sm text-secondary align-middle ms-2"></span>
              )}
              <button type="button" className="btn btn-primary" onClick={doLogout}>
                Log out
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { AsideMenuModal };
