import * as Yup from "yup";
import clsx from "clsx";
import GoogleAuth from "./googleAuth";
import { authenticationSuccess } from "../../../store/slice/utils";
import { FormFieldError } from "../../../components/atoms/FormFiledError";
import { setNotification } from "../../../store/slice/miscSlice";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useForgotPasswordMutation, useLoginMutation } from "../../../store/apiSlice/authApiSlice";
import { useNotification } from "../../../hooks/useNotification";
import { addEmailInResetPassword } from "../../../store/slice/authSlice";
import { useNavigate } from "react-router-dom";

/**
 * The Login component for user authentication.
 */
export function Login() {
  const dispatch = useDispatch();
  const notify = useNotification();
  const navigate = useNavigate();
  const [login, { data, error, isError, isLoading, isSuccess }] = useLoginMutation();
  const [isPasswordRequired, setIsPasswordRequired] = useState(false);
  const [forgotPassword] = useForgotPasswordMutation();

  // Validation schema for login form
  const loginSchema = isPasswordRequired =>
    Yup.object().shape({
      email: Yup.string().email("Wrong email format").required("Email is required"),
      password: Yup.string().when([], (_, schema) =>
        isPasswordRequired
          ? schema.required("Password is required")
          : schema.nullable().notRequired(),
      ),
    });

  // Formik initialization for form handling
  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema: loginSchema,
    onSubmit: async values => {
      const payload = isPasswordRequired ? values : { email: values.email };
      await login(payload);
    },
  });

  // Handle successful login
  useEffect(() => {
    if (isSuccess) {
      if (data?.code === "VALID_USER") {
        setIsPasswordRequired(true);
      } else {
        dispatch(authenticationSuccess(data));
      }
    }
  }, [dispatch, data, isSuccess]);

  // Handle login errors
  useEffect(() => {
    if (isError) {
      error.status === "FETCH_ERROR"
        ? dispatch(
            setNotification({
              type: "error",
              message: "Server Connection Failed. Please retry after a short while.",
            }),
          )
        : formik.setErrors(error?.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, isError]);

  // Function to reset email input
  const changeEmail = () => {
    setIsPasswordRequired(false);
    formik.resetForm();
  };

  const handlePassword = () => {
    let eye_slash = document.getElementById("eye-slash").classList;
    let eye = document.getElementById("eye").classList;
    let password = document.getElementById("orderlink_login_password_input");

    const type = password.getAttribute("type") === "password" ? "text" : "password";
    password.setAttribute("type", type);

    eye_slash.value.includes("d-none") ? eye_slash.remove("d-none") : eye_slash.add("d-none");
    eye.value.includes("d-none") ? eye.remove("d-none") : eye.add("d-none");
  };

  const handleForgotPass = async event => {
    event.preventDefault();
    try {
      const response = await forgotPassword({ email: formik.values.email }).unwrap();
      notify({ type: "success", message: response.detail });
      dispatch(addEmailInResetPassword(formik.values.email));
      navigate("/change_password/");
    } catch (error) {
      notify({ type: "error", message: error.data?.detail || "Something Went Wrong" });
    }
  };

  return (
    <div className="w-75 w-lg-50 py-10">
      <div className="text-center mb-12">
        <h1 className="fw-bolder">Sign In</h1>
      </div>
      <div className="row mb-12">
        <div className="col-8 offset-2">
          <GoogleAuth />
        </div>
      </div>
      <div className="separator separator-content mt-15 mb-12">
        <span className="w-125px text-gray-500 fw-semibold fs-7">Or with email</span>
      </div>
      <form id="orderlink_login_form" autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
        <div className="fv-row mb-8">
          <label
            className="form-label fs-6 fw-bolder text-dark"
            htmlFor="orderlink_login_email_input"
          >
            Email
          </label>
          {isPasswordRequired ? (
            <div className="d-flex justify-content-between form-control bg-transparent is-valid">
              <span>{formik.values.email}</span>
              <span className="cursor-pointer text-primary" onClick={changeEmail}>
                Change
              </span>
            </div>
          ) : (
            <>
              <input
                {...formik.getFieldProps("email")}
                id="orderlink_login_email_input"
                className={clsx(
                  "form-control bg-transparent",
                  { "is-invalid": formik.touched.email && formik.errors.email },
                  { "is-valid": formik.touched.email && !formik.errors.email },
                )}
                autoComplete="off"
                name="email"
                placeholder="Email"
                type="email"
              />
              <FormFieldError message={formik.touched.email && formik.errors.email} />
            </>
          )}
        </div>
        {isPasswordRequired && (
          <div className="fv-row mb-8 fv-plugins-icon-container" data-kt-password-meter="true">
            <div className="d-flex justify-content-between">
              <label
                className="form-label fw-bolder text-dark fs-6"
                htmlFor="orderlink_login_password_input"
              >
                Password
              </label>
              <span
                className="text-primary-color cursor-pointer h6"
                onClick={event => handleForgotPass(event)}
              >
                Forgot Password
              </span>
            </div>
            <div className="position-relative">
              <input
                {...formik.getFieldProps("password")}
                id="orderlink_login_password_input"
                className={clsx(
                  "form-control bg-transparent pe-15",
                  //   { "is-valid": formik.touched.password && !formik.errors.password },
                  { "is-invalid": formik.touched.password && formik.errors.password },
                )}
                autoComplete="off"
                name="password"
                placeholder="Password"
                type="password"
              />
              <span
                className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-0"
                data-kt-password-meter-control="visibility"
                onClick={handlePassword}
              >
                <i id="eye-slash" className="ki-outline ki-eye-slash fs-2"></i>{" "}
                <i id="eye" className="ki-outline ki-eye fs-2 d-none"></i>{" "}
              </span>
            </div>
            <FormFieldError message={formik.touched.password && formik.errors.password} />
          </div>
        )}
        <div className="d-grid mb-10">
          <button
            id="orderlink_login_submit"
            disabled={isLoading || !formik.isValid}
            form="orderlink_login_form"
            className="btn btn-primary"
            type="submit"
          >
            {isLoading ? (
              <span className="d-block indicator-progress">
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            ) : (
              <span className="indicator-label">Continue</span>
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
