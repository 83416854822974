import { addNotification } from "../store/slice/miscSlice";
import { baseURL, wsURL } from "../constant";
import { logOutUser } from "../store/slice/utils";
import { store } from "../store/store";

class WebSocketService {
  constructor() {
    this.socket = null;
    this.wsUrl = `${wsURL}/notifications/`;
  }

  /**
   * Initializes the WebSocket connection and sets up event listeners.
   */
  createWebSocket() {
    this.socket = new WebSocket(this.wsUrl);

    this.socket.addEventListener("close", this.handleClose);
    this.socket.addEventListener("error", this.handleError);
    this.socket.addEventListener("message", this.handleMessage);
  }

  /**
   * Handles incoming WebSocket messages, dispatching actions based on content.
   */
  handleMessage = event => {
    const data = JSON.parse(event.data);

    // Check for the refresh_token action and refresh the token if required.
    if (data.action && data.action === "refresh_token") {
      this.refreshToken();
    } else {
      // Dispatch received messages to the Redux store.
      store.dispatch(addNotification(data));
    }
  };

  /**
   * Handles WebSocket closure by attempting to reconnect after a delay.
   */
  handleClose = () => {
    if (process.env.NODE_ENV === "production") {
      setTimeout(() => this.createWebSocket(), 5000);
    }
  };

  /**
   * Logs WebSocket errors and ensures the connection is closed.
   */
  handleError = error => {
    this.socket.close();
  };

  /**
   * Closes the current WebSocket connection and removes event listeners.
   */
  closeWebSocket() {
    if (this.socket) {
      this.socket.removeEventListener("message", this.handleMessage);
      this.socket.removeEventListener("close", this.handleClose);
      this.socket.removeEventListener("error", this.handleError);
      this.socket.close();
      this.socket = null;
    }
  }

  /**
   * Initiates a token refresh and attempts to reconnect the WebSocket on success.
   */
  refreshToken() {
    fetch(`${baseURL}/api_user/refresh_token/`, {
      method: "GET",
      credentials: "include",
    })
      .then(response => {
        if (response.ok) {
          this.reconnectWebSocket();
        } else {
          store.dispatch(logOutUser());
        }
      })
      .catch(error => store.dispatch(logOutUser()));
  }

  /**
   * Reconnects the WebSocket by first closing any existing connection.
   */
  reconnectWebSocket() {
    this.closeWebSocket();
    this.createWebSocket();
  }
}

const webSocketService = new WebSocketService();
export default webSocketService;
