import { apiSlice } from "./apiSlice";
import { authOnSuccess, authenticationFailed } from "../slice/utils";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    autoLogin: builder.query({
      query: () => "/api_user/autologin/",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(authOnSuccess(result.data)))
          .catch(() => dispatch(authenticationFailed()));
      },
    }),
    createPassword: builder.mutation({
      query: data => ({ url: "/user/create_pass/", method: "PATCH", body: data }),
    }),
    forgotPassword: builder.mutation({
      query: data => ({ url: "/api_user/forgot_password/", method: "POST", body: data }),
    }),
    googleLogin: builder.mutation({
      query: credentials => ({ url: "/user/google/", method: "POST", body: credentials }),
    }),
    login: builder.mutation({
      query: data => ({ url: "/api_user/login/", method: "POST", body: data }),
    }),
    logout: builder.mutation({
      query: data => ({ url: "/api_user/logout/", method: "POST", body: data }),
    }),
    resetPassword: builder.mutation({
      query: data => ({ url: "/api_user/reset_password/", method: "POST", body: data }),
    }),
    sendOTP: builder.mutation({
      query: data => ({ url: "/api_affirm/otp/", method: "POST", body: data }),
    }),
    signup: builder.mutation({
      query: credentials => ({ url: "/user/signup/", method: "POST", body: credentials }),
    }),
    updatePassword: builder.mutation({
      query: data => ({ url: "/user/update_pass/", method: "PATCH", body: data }),
    }),
    validateToken: builder.query({ query: token => `/user/validate_token/${token}/` }),
    verifyOTP: builder.mutation({
      query: ({ id, ...restData }) => ({
        url: `/api_affirm/otp/${id}/verify_otp/`,
        method: "POST",
        body: restData,
      }),
    }),
  }),
});

export const {
  useAutoLoginQuery,
  useCreatePasswordMutation,
  useForgotPasswordMutation,
  useGoogleLoginMutation,
  useLoginMutation,
  useLogoutMutation,
  useResetPasswordMutation,
  useSendOTPMutation,
  useSignupMutation,
  useUpdatePasswordMutation,
  useValidateTokenQuery,
  useVerifyOTPMutation,
} = authApiSlice;
