import * as XLSX from "xlsx";
import { KTCard, KTIcon } from "../../../_metronic/helpers";
import { useEffect } from "react";
import { useGetProductsQuery } from "../../../store/apiSlice/productApiSlice";
import {
  useGetPurchaseOrderFieldsQuery,
  useGetPurchaseOrderLineFieldsQuery,
  useGetPurchaseOrderPriorityQuery,
} from "../../../store/apiSlice/purchaseOrderApiSlice";
import {
  useGetSuppliersQuery,
  useGetWarehouseQuery,
} from "../../../store/apiSlice/supplierApiSlice";
import { useModalView } from "../Provider/Open";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const CreatePurchaseOrderAction = () => {
  const { clearCurrentAction, currentAction } = useModalView();

  useEffect(() => {
    const body = document.body;
    if (currentAction === "createPurchaseOrderAction") {
      body.classList.add("modal-open");
    }
    return () => body.classList.remove("modal-open");
  }, [currentAction]);

  if (!(currentAction === "createPurchaseOrderAction")) return;

  return (
    <>
      <div className="modal fade show d-block" aria-modal="true" role="dialog" tabIndex={-1}>
        <div className="modal-dialog modal-dialog-centered mw-600px">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="m-0">Create Purchase Orders</h2>
              <span onClick={() => clearCurrentAction()}>
                <KTIcon className="cursor-pointer fs-2x text-hover-danger" iconName="cross" />
              </span>
            </div>
            <div className="modal-body scroll-y">
              <Actions />
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </>
  );
};

const Actions = () => {
  const { setCurrentAction, clearCurrentAction } = useModalView();
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/orders/purchase/create");
    clearCurrentAction();
  };

  const { purchaseOrderFields, purchaseOrderLineFields, purchaseOrderPriority } = useSelector(
    state => state.purchaseOrder,
  );
  const { products } = useSelector(state => state.product);
  const { suppliers, warehousesSelection } = useSelector(state => state.supplier);

  useGetPurchaseOrderFieldsQuery(null, { refetchOnMountOrArgChange: true });
  useGetPurchaseOrderLineFieldsQuery(null, { refetchOnMountOrArgChange: true });
  useGetSuppliersQuery("mode=selection", { refetchOnMountOrArgChange: true });
  useGetWarehouseQuery("type=selection", { refetchOnMountOrArgChange: true });
  useGetProductsQuery("type=selection", { refetchOnMountOrArgChange: true });
  useGetPurchaseOrderPriorityQuery("", { refetchOnMountOrArgChange: true });

  /**
   * Generates and exports an Excel file with multiple sheets based on current data.
   *
   * This function prepares various data arrays into Excel sheets, modifies specific
   * fields as needed (e.g., `wh_opr_type` to `warehouse`), removes unnecessary fields
   * (e.g., `id` column), and compiles them into a single Excel workbook for export.
   */
  const handleExcel = () => {
    // Clone and update purchaseOrderFields: replace "wh_opr_type" with "warehouse" and remove "id"
    const updatedFields = purchaseOrderFields
      .map(item => (item === "wh_opr_type" ? "warehouse" : item))
      .filter(item => item !== "id");

    // Create sheets for purchase order fields and purchase order line fields
    const purchaseOrderFieldsSheet = XLSX.utils.aoa_to_sheet([updatedFields]);
    const purchaseOrderLineFieldsSheet = XLSX.utils.aoa_to_sheet([purchaseOrderLineFields]);

    // Helper function to ensure data is an array for JSON to sheet conversion
    const arrayToSheet = data => XLSX.utils.json_to_sheet(Array.isArray(data) ? data : []);

    // Convert additional data arrays to sheets, ensuring each is an array of objects
    const suppliersData = suppliers.map(item => ({
      code: item.code,
      name: item.name,
      currency: item.currency?.code,
    }));
    const suppliersSheet = arrayToSheet(suppliersData);

    const warehouseData = warehousesSelection.map(item => ({ code: item.code, name: item.name }));
    const warehousesSheet = arrayToSheet(warehouseData);

    const prdouctData = products.map(item => ({ number: item.number, name: item.name }));
    const productsSheet = arrayToSheet(prdouctData);

    const priorityData = purchaseOrderPriority.map(item => ({ name: item.name }));
    const prioritySheet = arrayToSheet(priorityData);

    // Create a new workbook and append all sheets
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, purchaseOrderFieldsSheet, "purchase_order");
    XLSX.utils.book_append_sheet(workbook, purchaseOrderLineFieldsSheet, "purchase_order_lines");
    XLSX.utils.book_append_sheet(workbook, suppliersSheet, "suppliers");
    XLSX.utils.book_append_sheet(workbook, warehousesSheet, "warehouses");
    XLSX.utils.book_append_sheet(workbook, productsSheet, "products");
    XLSX.utils.book_append_sheet(workbook, prioritySheet, "priority");

    // Write the workbook to an Excel file
    XLSX.writeFile(workbook, "purchaseorders.xlsx");
  };

  return (
    <>
      <div className="d-flex flex-column align-items-center gap-10 text-center">
        <KTCard className="w-100">
          <div className="container px-8 d-flex justify-content-between">
            <span
              onClick={() => handleNavigate()}
              className="d-flex flex-row align-items-center gap-5 text-start text-gray-800 cursor-pointer"
            >
              <div className="d-flex flex-column justify-content-start text-gray-800 text-hover-primary">
                <span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Individual</span>
                <span>Add one purchase order at a time</span>
              </div>
            </span>
          </div>
        </KTCard>

        <KTCard className="w-100">
          <div className="container px-8 d-flex justify-content-between">
            <div className="d-flex flex-column align-items-center gap-5 text-start text-gray-800">
              <div className="d-flex flex-column justify-content-start text-gray-800">
                <span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Bulk Upload</span>
                <span>
                  Download Excel template, input purchase order data and upload Excel file
                </span>
              </div>
              <div className="d-flex flex-row gap-10">
                <span onClick={handleExcel}>
                  <KTIcon
                    iconName="file-down"
                    className="fs-2tx me-n1 minimize-default cursor-pointer text-hover-primary"
                  />
                </span>
                <span onClick={() => setCurrentAction("uploadBulkPurchaseOrder")}>
                  <KTIcon
                    iconName="file-up"
                    className="fs-2tx me-n1 minimize-default cursor-pointer text-hover-primary"
                  />
                </span>
              </div>
            </div>
          </div>
        </KTCard>
      </div>
    </>
  );
};

export { CreatePurchaseOrderAction };
