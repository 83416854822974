import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currency: [],
};

const currencySlice = createSlice({
  name: "currency",
  initialState,
  reducers: {
    getCurrency: (state, action) => {
      state.currency = action.payload;
    },
    resetCurrency: state => {
      state.currency = initialState.currency;
    },
  },
});

export const { getCurrency, resetCurrency } = currencySlice.actions;

export default currencySlice.reducer;
