import { createContext, useContext, useState } from "react";

import { initialListView } from "../../../_metronic/helpers";

const OpenModalViewContext = createContext(initialListView);

const OpenModalViewProvider = ({ children }) => {
  const [currentAction, setCurrentAction] = useState(null);

  return (
    <OpenModalViewContext.Provider
      value={{ currentAction, setCurrentAction, clearCurrentAction: () => setCurrentAction(null) }}
    >
      {children}
    </OpenModalViewContext.Provider>
  );
};

const useModalView = () => useContext(OpenModalViewContext);

export { OpenModalViewProvider, useModalView };
