import { mutateFiles } from "./slice/fileSlice";
import { baseURL } from "../constant";

const serverSideEventMiddleware = () => {
  const connections = {};
  const actionMap = {
    docDigit: mutateFiles,
  };

  const startEventSource = (dispatch, url, key) => {
    if (!connections[key]) {
      const eventSource = new EventSource(`${baseURL}/${url}/`);

      eventSource.onmessage = event => {
        const data = JSON.parse(event.data);
        const actionCreator = actionMap[key];
        if (actionCreator) {
          dispatch(actionCreator(data));
        }
      };

      eventSource.onerror = error => {
        eventSource.close();
        delete connections[key];
      };

      connections[key] = eventSource;
    }
  };

  const stopEventSource = key => {
    if (connections[key]) {
      connections[key].close();
      delete connections[key];
    }
  };

  return store => next => action => {
    if (["fileSSE/start"].includes(action.type)) {
      const { url, key } = action.payload;
      startEventSource(store.dispatch, url, key);
    } else if (["fileSSE/stop"].includes(action.type)) {
      stopEventSource(action.payload.key);
    }
    return next(action);
  };
};

const sseMiddleware = serverSideEventMiddleware();
export { sseMiddleware };
