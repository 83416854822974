import { useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { authenticationSuccess } from "../../../store/slice/utils";
import { googleLoginApiClientID, googleLoginApiSrc } from "../../../constant";
import { useGoogleLoginMutation } from "../../../store/apiSlice/authApiSlice";

// Dynamically loads an external script into the document.
const loadScript = src => {
  return new Promise((resolve, reject) => {
    // Check if the script is already present in the document
    if (document.querySelector(`script[src="${src}"]`)) {
      resolve();
      return;
    }

    const script = document.createElement("script");
    script.src = src;

    // Event listeners for the script loading process.
    script.onload = resolve;
    script.onerror = () => {
      // Reject the promise with an Error object for better error handling.
      reject(new Error(`Failed to load script at url: ${src}`));
    };

    // Append the script to the document body to start the loading process.
    document.body.appendChild(script);
  });
};

const GoogleAuth = () => {
  const googleButton = useRef(null);

  const [googleLogin, { data, error, isLoading }] = useGoogleLoginMutation();

  const [displayError, setDisplayError] = useState(false);

  const dispatch = useDispatch();

  // Handles the response from the Google Sign-In process.
  async function handleCredentialResponse(response) {
    // Ensure there's a valid credential in the response.
    if (!response.credential) {
      return;
    }
    try {
      await googleLogin({ auth_token: response.credential }).unwrap();
    } catch (error) {}
  }

  // Initializes the Google Sign-In functionality upon component mount.
  useEffect(() => {
    // Constants for Google Sign-In script source and client ID.
    const src = googleLoginApiSrc;
    const id = googleLoginApiClientID;

    // Load the Google Sign-In script.
    loadScript(src)
      .then(() => {
        /*global google*/

        // Ensure the 'google' global is available after loading the script.
        if (typeof google === "undefined" || !google.accounts || !google.accounts.id) {
          throw new Error("Failed to initialize Google Sign-In. Google global is unavailable.");
        }

        // Initialize the Google Sign-In functionality.
        google.accounts.id.initialize({
          client_id: id,
          callback: handleCredentialResponse,
        });

        // Render the Google Sign-In button.
        google.accounts.id.renderButton(googleButton.current, { theme: "outline", size: "large" });
      })
      .catch(error => {
        // Log any errors that occurred during the script loading or initialization process.
      });

    // Cleanup function to remove the dynamically loaded script upon component unmount.
    return () => {
      const scriptTag = document.querySelector(`script[src="${src}"]`);
      if (scriptTag) {
        document.body.removeChild(scriptTag);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, displayError]);

  useEffect(() => {
    if (data) {
      dispatch(authenticationSuccess(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    let timeoutId;
    if (error) {
      switch (error.status) {
        case "FETCH_ERROR":
          setDisplayError("Server Connection Error");
          break;
        case 401:
        case 403:
          setDisplayError(error.data?.detail || "An unexpected error occurred.");
          break;
        default:
          setDisplayError("Something Went Error");
      }
      timeoutId = setTimeout(() => {
        setDisplayError(false);
      }, 3000);
    }

    // Cleanup function
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [error]);

  return (
    <div className="d-flex justify-content-center">
      {displayError ? (
        <div className="alert alert-danger">
          <div className="alert-text font-weight-bold">{displayError}</div>
        </div>
      ) : !isLoading ? (
        <div id="google-login-btn">
          <div id="google-ref" ref={googleButton}></div>
        </div>
      ) : (
        <span className="spinner-border text-primary" role="status"></span>
      )}
    </div>
  );
};

export default GoogleAuth;
