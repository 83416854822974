import * as Yup from "yup";
import clsx from "clsx";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useNotification } from "../../../hooks/useNotification";
import { FormFieldError } from "../../../components/atoms/FormFiledError";
import { useResetPasswordMutation } from "../../../store/apiSlice/authApiSlice";
import { addEmailInResetPassword } from "../../../store/slice/authSlice";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const notify = useNotification();
  const { resetPasswordEmail } = useSelector(state => state.auth);
  const [resetPassword, { isLoading }] = useResetPasswordMutation();

  // Validation schema for reset password form
  const passwordSchema = Yup.object().shape({
    otp: Yup.string().required("OTP is required"),
    new_password: Yup.string()
      .min(8, "Password must be 8 characters long")
      .matches(/[0-9]/, "Password requires a number")
      .matches(/[a-z]/, "Password requires a lowercase letter")
      .matches(/[A-Z]/, "Password requires an uppercase letter")
      .matches(/[^\w]/, "Password requires a symbol"),
    confirm_password: Yup.string()
      .required("Password is required")
      .oneOf([Yup.ref("new_password")], "Must match `New Password` field value."),
  });

  // Formik initialization for form handling
  const formik = useFormik({
    initialValues: { new_password: "", confirm_password: "", email: "", otp: "" },
    validationSchema: passwordSchema,
    onSubmit: async values => {
      try {
        values.email = resetPasswordEmail;
        const response = await resetPassword(values).unwrap();
        notify({ type: "success", message: response.detail });
        formik.resetForm();
        dispatch(addEmailInResetPassword(""));
        navigate("/login/");
      } catch (error) {
        notify({ type: "error", message: error.data?.detail || "Something Went Wrong" });
      }
    },
  });

  const handleNewPassword = () => {
    let eye_slash = document.getElementById("eye-slash").classList;
    let eye = document.getElementById("eye").classList;
    let password = document.getElementById("orderlink_reset_new_password_input");

    const type = password.getAttribute("type") === "password" ? "text" : "password";
    password.setAttribute("type", type);

    eye_slash.value.includes("d-none") ? eye_slash.remove("d-none") : eye_slash.add("d-none");
    eye.value.includes("d-none") ? eye.remove("d-none") : eye.add("d-none");
  };

  const handleConfirmPassword = () => {
    let eye_slash = document.getElementById("eye-slash").classList;
    let eye = document.getElementById("eye").classList;
    let password = document.getElementById("orderlink_reset_confirm_password_input");

    const type = password.getAttribute("type") === "password" ? "text" : "password";
    password.setAttribute("type", type);

    eye_slash.value.includes("d-none") ? eye_slash.remove("d-none") : eye_slash.add("d-none");
    eye.value.includes("d-none") ? eye.remove("d-none") : eye.add("d-none");
  };

  return (
    <>
      <div className="w-75 w-lg-50 py-10">
        <div className="text-center mb-12">
          <h1 className="fw-bolder">Reset Password</h1>
        </div>
        <form
          id="orderlink_reset_password_form"
          autoComplete="off"
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <div className="fv-row mb-8">
            <label
              className="form-label fs-6 fw-bolder text-dark"
              htmlFor="orderlink_reset_otp_input"
            >
              Security Code
            </label>
            <input
              {...formik.getFieldProps("otp")}
              id="orderlink_reset_otp_input"
              className={clsx("form-control bg-transparent pe-20", {
                "is-invalid": formik.touched.otp && formik.errors.otp,
              })}
              autoComplete="off"
              name="otp"
              placeholder="Security Code"
              type="number"
            />
            <FormFieldError message={formik.touched.otp && formik.errors.otp} />
          </div>
          <div className="fv-row mb-8">
            <div className="d-flex flex-column">
              <label
                className="form-label fs-4 fw-bolder text-dark password-tooltip"
                htmlFor="orderlink_reset_new_password_input"
              >
                New Password
              </label>
            </div>
            <div className="position-relative">
              <input
                {...formik.getFieldProps("new_password")}
                id="orderlink_reset_new_password_input"
                className={clsx("form-control bg-transparent pe-20", {
                  "is-invalid": formik.touched.new_password && formik.errors.new_password,
                })}
                autoComplete="off"
                name="new_password"
                placeholder="New Password"
                type="password"
              />
              <span
                className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-4"
                data-kt-password-meter-control="visibility"
                onClick={handleNewPassword}
              >
                <i id="eye-slash" className="ki-outline ki-eye-slash fs-2"></i>{" "}
                <i id="eye" className="ki-outline ki-eye fs-2 d-none"></i>{" "}
              </span>
            </div>
            <FormFieldError message={formik.touched.new_password && formik.errors.new_password} />
            <label className="text-gray-700 mt-2 text-justify">
              Password must have at least a number, symbol, uppercase, lowercase and minimum 8
              characters.
            </label>
          </div>
          <div className="fv-row mb-8">
            <label
              className="form-label fs-6 fw-bolder text-dark"
              htmlFor="orderlink_reset_confirm_password_input"
            >
              Confirm Password
            </label>
            <div className="position-relative">
              <input
                {...formik.getFieldProps("confirm_password")}
                id="orderlink_reset_confirm_password_input"
                className={clsx("form-control bg-transparent pe-20", {
                  "is-invalid": formik.touched.confirm_password && formik.errors.confirm_password,
                })}
                autoComplete="off"
                name="confirm_password"
                placeholder="Confirm Password"
                type="password"
              />
              <span
                className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-4"
                data-kt-password-meter-control="visibility"
                onClick={handleConfirmPassword}
              >
                <i id="eye-slash" className="ki-outline ki-eye-slash fs-2"></i>{" "}
                <i id="eye" className="ki-outline ki-eye fs-2 d-none"></i>{" "}
              </span>
            </div>
            <FormFieldError
              message={formik.touched.confirm_password && formik.errors.confirm_password}
            />
          </div>
          <div className="d-grid mb-10">
            <button
              id="orderlink_reset_password_submit"
              disabled={isLoading || !formik.isValid}
              form="orderlink_reset_password_form"
              className="btn btn-primary"
              type="submit"
            >
              {isLoading ? (
                <span className="d-block indicator-progress">
                  Please wait...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              ) : (
                <span className="indicator-label">Continue</span>
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export { ChangePassword };
