import { Link } from "react-router-dom";

import { KTIcon, toAbsoluteUrl } from "../../../helpers";
import { useLayout } from "../../core";
import { AsideMenu } from "./AsideMenu";

const AsideDefault = () => {
  const { classes, config } = useLayout();
  const { aside } = config;

  return (
    <>
      <div
        id="kt_aside"
        className="aside sidebar_wrapper"
        data-kt-drawer="true"
        data-kt-drawer-name="aside"
        data-kt-drawer-activate="{default: true, lg: false}"
        data-kt-drawer-overlay="true"
        data-kt-drawer-width="{default:'200px', '300px': '250px'}"
        data-kt-drawer-direction="start"
        data-kt-drawer-toggle="#kt_aside_mobile_toggle"
      >
        <div className="header-brand d-none d-lg-flex flex-row w-100 h-75px justify-content-center align-items-center px-10">
          <Link to="/">
            <img
              alt="Logo"
              src={toAbsoluteUrl("/media/orderlink_logos/Blue.svg")}
              className="ms-auto w-80 h-100 desktop-logo"
            />
          </Link>

          {aside.minimize && (
            <div
              id="kt_aside_toggle"
              className="btn btn-icon w-30px h-30px px-0 btn-active-color-primary aside-minimize"
              data-kt-toggle="true"
              data-kt-toggle-state="active"
              data-kt-toggle-target="body"
              data-kt-toggle-name="aside-minimize"
            >
              <KTIcon iconName="exit-left" className="fs-1 me-n1 minimize-default" />
              <KTIcon iconName="entrance-left" className="fs-1 minimize-active" />
            </div>
          )}
        </div>
        <div className="aside-menu flex-column-fluid">
          <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
        </div>
      </div>
    </>
  );
};

export { AsideDefault };
