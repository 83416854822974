import clsx from "clsx";
import { Link } from "react-router-dom";
import { Separator } from "../../components/atoms/Separator";
import { timeStampInCurrentTZ } from "../../helper/utils";
import {
  useMarkAllNotificationsAsReadMutation,
  usePatchNotificationToIsreadMutation,
} from "../../store/apiSlice/notificationApiSlice";
import { useNotification } from "../../hooks/useNotification";

const MarkAllAsRead = () => {
  const notify = useNotification();

  const [markAllNotificationsAsRead, { isLoading }] = useMarkAllNotificationsAsReadMutation();

  const markAllAsRead = async () => {
    try {
      await markAllNotificationsAsRead().unwrap();
    } catch (error) {
      notify({
        type: "error",
        message: error.data?.detail || "Failed to mark all notifications as read.",
      });
    }
  };

  return (
    <div className="py-2 text-center">
      <button className="btn btn-primary btn-sm w-50" type="button" onClick={markAllAsRead}>
        {isLoading ? (
          <span className="d-block indicator-progress">
            <span className="spinner-border spinner-border-sm align-middle"></span>
          </span>
        ) : (
          <span className="indicator-label">Mark All As Read</span>
        )}
      </button>
    </div>
  );
};

const Notification = ({ item }) => {
  const { id, is_read, notification } = item || {};
  const { content, date_of_creation, relative_url } = notification || {};

  const [patchNotificationToIsread] = usePatchNotificationToIsreadMutation();

  const markAsRead = async () => {
    try {
      await patchNotificationToIsread({ id, is_read: true }).unwrap();
    } catch (error) {}
  };

  return (
    <div className={clsx("fs-6 fw-bold", { "text-primary": !is_read })}>
      <div className={clsx({ "cursor-pointer": !is_read })} onClick={markAsRead}>
        {relative_url ? (
          <Link className={clsx({ "text-dark": is_read })} to={relative_url}>
            {content}
          </Link>
        ) : (
          content
        )}
      </div>
      <div className="my-2 fs-8 text-gray-500">
        {timeStampInCurrentTZ(date_of_creation).format("MMM DD, YYYY. hh:mm a")}
      </div>
      <Separator />
    </div>
  );
};

const NotificationDetail = ({ notifications, unreadNotificationsCount }) => {
  return (
    <div
      className=" fs-base fw-semibold menu menu-active-bg menu-column menu-icon-muted menu-rounded menu-state-primary menu-sub menu-sub-dropdown menu-title-gray-700 pb-4 w-350px w-lg-375px "
      data-kt-menu="true"
    >
      {/* begin::Menu item */}
      <div className="bg-primary d-flex flex-column rounded-top px-5 py-7">
        <h3 className="fw-bold text-white m-0">Notifications</h3>
      </div>
      <div className="tab-content">
        <div className="tab-pane fade show active" role="tabpanel">
          <div className="d-flex flex-column">
            <div className="scroll-y overflow-y-auto mh-300px my-5 px-7">
              {Array.isArray(notifications) && notifications.length > 0 ? (
                notifications.map((item, index) => (
                  <Notification key={`${index}_alert`} item={item} />
                ))
              ) : (
                <div className="d-flex justify-content-center align-items-center text-gray-700 mt-4">
                  No Notification
                </div>
              )}
            </div>
            {unreadNotificationsCount > 0 && <MarkAllAsRead />}
          </div>
        </div>
      </div>
    </div>
  );
};

export { NotificationDetail };
