import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  docTypes: [],

  file: null,
  files: { count: 0, results: [] },
  filesParams: { limit: 10, offset: 0 },

  documentCategoriesSelection: [],
  documentSubCategoriesSelection: [],
};

const fileSlice = createSlice({
  name: "file",
  initialState,
  reducers: {
    createFiles: (state, action) => {
      state.files.count = state.files.count + action.payload.length;
      state.files.results = [...action.payload, ...state.files.results].slice(
        0,
        state.filesParams.limit,
      );
    },
    deleteFile: (state, action) => {
      state.files.results = state.files.results.filter(item => item.id !== action.payload.id);
    },
    getDocTypes: (state, action) => {
      state.docTypes = action.payload;
    },
    getFile: (state, action) => {
      state.file = action.payload;
    },
    getFiles: (state, action) => {
      state.files = action.payload;
    },
    mutateFiles: (state, action) => {
      const index = state.files.results.findIndex(item => item.id === action.payload.id);
      if (index !== -1) {
        state.files.results[index] = action.payload;
      }
    },
    resetDocTypes: state => {
      state.docTypes = initialState.docTypes;
    },
    resetDocumentCategoriesSelection: (state, action) => {
      state.documentCategoriesSelection = initialState.documentCategoriesSelection;
    },
    resetDocumentSubCategoriesSelection: (state, action) => {
      state.documentSubCategoriesSelection = initialState.documentCategoriesSelection;
    },
    resetFile: state => {
      state.file = null;
    },
    resetFiles: state => {
      state.files = initialState.files;
    },
    resetFilesParams: state => {
      state.filesParams = { limit: state.filesParams.limit, offset: 0 };
    },
    setDocumentCategoriesSelection: (state, action) => {
      state.documentCategoriesSelection = action.payload;
    },
    setDocumentSubCategoriesSelection: (state, action) => {
      state.documentSubCategoriesSelection = action.payload;
    },
    setFilesParams: (state, action) => {
      state.filesParams = action.payload;
    },
    updateComplianceFile: (state, action) => {
      const index = state.files.results.findIndex(file => file.id === action.payload.id);
      if (index !== -1) {
        state.files.results[index] = {
          ...state.files.results[index],
          document_type: action.payload.document_type,
          reference_no: action.payload.reference_no,
          certificate_type: action.payload.certificate_type,
          issued_date: action.payload.issued_date,
          expiry_date: action.payload.expiry_date,
          status: action.payload.status,
        };
      }
    },
    updateFile: (state, action) => {
      const index = state.files.results.findIndex(file => file.id === action.payload.id);
      if (index !== -1) {
        state.files.results[index] = {
          ...state.files.results[index],
          document_type: action.payload.document_type,
          reference_no: action.payload.reference_no,
        };
      }
    },
    updateFileApproval: (state, action) => {
      const index = state.files.results.findIndex(file => file.id === action.payload.document);
      if (index !== -1) {
        state.files.results[index] = {
          ...state.files.results[index],
          approval: action.payload.approved_by,
        };
      }
    },
    updateFilesParams: (state, action) => {
      state.filesParams = { ...state.filesParams, ...action.payload };
    },
    updateFileStatus: (state, action) => {
      const index = state.files.results.findIndex(file => file.id === action.payload.document);
      if (index !== -1) {
        state.files.results[index] = {
          ...state.files.results[index],
          status: action.payload.approval_status,
        };
      }
    },
    updateFileV2: (state, action) => {
      const index = state.files.results.findIndex(file => file.id === action.payload.id);
      if (index !== -1) {
        state.files.results[index] = action.payload;
      }
    },
    updateMultiFileStatus: (state, action) => {
      const documents = action.payload.document;
      documents.forEach(document => {
        const index = state.files.results.findIndex(file => file.id === document);
        if (index !== -1) {
          state.files.results[index] = { ...state.files.results[index], status: "Approved" };
        }
      });
    },
  },
});

export const {
  createFiles,
  deleteFile,
  getDocTypes,
  getFile,
  getFiles,
  mutateFiles,
  resetDocTypes,
  resetDocumentCategoriesSelection,
  resetDocumentSubCategoriesSelection,
  resetFile,
  resetFiles,
  resetFilesParams,
  setDocumentCategoriesSelection,
  setDocumentSubCategoriesSelection,
  setFilesParams,
  updateComplianceFile,
  updateFile,
  updateFileApproval,
  updateFilesParams,
  updateFileStatus,
  updateFileV2,
  updateMultiFileStatus,
} = fileSlice.actions;

export default fileSlice.reducer;
