import { AuthLayout } from "./AuthLayout";
import { Login } from "./components/Login";
import { Navigate, Route, Routes } from "react-router-dom";
import { ChangePassword } from "./components/ChangePassword";

const AuthPage = () => (
  <Routes>
    <Route path="login" element={<AuthLayout />}>
      <Route index element={<Login />} />
    </Route>
    <Route path="change_password/" element={<AuthLayout />}>
      <Route index element={<ChangePassword />} />
    </Route>
    <Route path="*" element={<Navigate to="/login" />} />
  </Routes>
);

export { AuthPage };
