import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  createWatchlists: { count: 0 },

  poLine: null,
  poLines: { count: 0, results: [] },
  poLinesParams: { limit: 5, offset: 0 },
  purchaseOrderLinesParams: { limit: 10, offset: 0, type: "po_lines" },
  downloadedPOLines: [],

  consolidatePOLines: { count: 0, results: [] },

  poWatchlist: [],
  poWatchlistDetail: [],
  purchaseOrderFields: [],
  purchaseOrderLineFields: [],
  purchaseOrderLineStatus: [],
  downloadedPurchaseOrders: [],

  poWatchlistLate: { count: 0, results: [] },
  poWatchlistLateParams: { limit: 10, offset: 0 },

  purchaseOrder: null,
  purchaseOrderBillsParams: { limit: 10, offset: 0 },
  purchaseOrderFilesParams: { limit: 10, offset: 0 },
  purchaseOrders: { count: 0, results: [] },
  purchaseOrdersParams: { limit: 100, offset: 0 },
  purchaseOrdersSelection: [],

  purchaseOrderTrack: [
    { level: "one", name: "Order Created", date: "" },
    { level: "two", name: "Order Accepted", date: "" },
    { level: "three", name: "Order Dispatched", date: "" },
    { level: "four", name: "Order Received", date: "" },
    { level: "five", name: "Order Receipted", date: "" },
  ],

  purchaseOrderStatus: [],
  purchaseOrderPriority: [],

  watchlistIDs: [],

  purchaseOrderPOLines: [],
};

const purchaseOrderSlice = createSlice({
  name: "purchaseOrder",
  initialState,
  reducers: {
    addActivityLogToPurchaseOrder: (state, action) => {
      state.purchaseOrder = { ...state.purchaseOrder, ...action.payload };
    },
    addPurchaseOrderFiles: (state, action) => {
      state.purchaseOrder = {
        ...state.purchaseOrder,
        files: {
          count: (state.purchaseOrder?.files?.count || 0) + action.payload.length,
          results: [...action.payload, ...(state.purchaseOrder?.files?.results || [])],
        },
      };
    },
    addPurchaseOrderNote: (state, action) => {
      if (!state.purchaseOrder) {
        state.purchaseOrder = {};
      }
      state.purchaseOrder.notes = [action.payload, ...state.purchaseOrder.notes];
    },
    addPurchaseOrderWatchlist: (state, action) => {
      state.createWatchlists = {
        ...state.createWatchlists,
        count: state.createWatchlists.count + 1,
      };
      if (action.payload.message === "update") {
        state.purchaseOrdersParams = { limit: 10, offset: 0 };
      }
    },
    createMultiplePurchaseOrders: (state, action) => {
      state.purchaseOrders.count += action.payload.length;
      state.purchaseOrders.results = [...state.purchaseOrders.results, ...action.payload];
    },
    doVerifyPO: (state, action) => {
      state.purchaseOrder = { ...state.purchaseOrder, ...action.payload };
    },
    editMultiPurchaseOrderLines: (state, action) => {
      const response = action.payload;
      if (state.poLines.results.length > 0) {
        response.ids.forEach(item => {
          const index = state.poLines.results.findIndex(order_line => order_line.id === item);
          if (index !== -1) {
            state.poLines.results[index] = { ...state.poLines.results[index], eta: response.eta };
          }
        });
      }
      if (state.purchaseOrder) {
        response.ids.forEach(item => {
          const index = state.purchaseOrder.po_line.findIndex(order_line => order_line.id === item);
          if (index !== -1) {
            state.purchaseOrder.po_line[index] = {
              ...state.purchaseOrder.po_line[index],
              eta: response.eta,
            };
          }
        });
      }
    },
    getDownloadedPurchaseOrderLines: (state, action) => {
      state.downloadedPOLines = action.payload;
    },
    getDownloadedPurchaseOrders: (state, action) => {
      state.downloadedPurchaseOrders = action.payload;
    },
    getPOWatchlistLate: (state, action) => {
      const data = action.payload;
      state.poWatchlistLate = { count: data.length, results: data };
    },
    getPurchaseOrder: (state, action) => {
      if (!state.purchaseOrder) {
        state.purchaseOrder = {};
      }

      state.purchaseOrder = { ...state.purchaseOrder, ...action.payload };
    },
    getPurchaseOrderFields: (state, action) => {
      state.purchaseOrderFields = action.payload;
    },
    getPurchaseOrderLineFields: (state, action) => {
      state.purchaseOrderLineFields = action.payload;
    },
    getPurchaseOrderLineStatus: (state, action) => {
      state.purchaseOrderLineStatus = action.payload;
    },
    getPurchaseOrderPOLines: (state, action) => {
      state.purchaseOrderPOLines = action.payload;
    },
    getPurchaseOrderPriority: (state, action) => {
      state.purchaseOrderPriority = action.payload;
    },
    getPurchaseOrders: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.purchaseOrdersSelection = action.payload;
      } else {
        state.purchaseOrders = action.payload;
      }
    },
    getPurchaseOrdersSelection: (state, action) => {
      state.purchaseOrdersSelection = action.payload;
    },
    getPurchaseOrderStatus: (state, action) => {
      state.purchaseOrderStatus = action.payload;
    },
    getPurchaseOrdersWatchlists: (state, action) => {
      state.poWatchlist = action.payload;
    },
    getPurchaseOrdersWatchlistsDetail: (state, action) => {
      state.poWatchlistDetail = action.payload;
    },
    getPurchaseOrderTrack: (state, action) => {
      state.purchaseOrderTrack = action.payload;
    },
    mutatePOLine: (state, action) => {
      let poLine = current(state.poLine);
      const poLineProductUomQty = poLine.stock_move ? Number(poLine.stock_move.product_uom_qty) : 0;
      const quantityDone = Number(action.payload.quantity_done);
      state.poLine.stock_move =
        poLineProductUomQty > quantityDone
          ? {
              ...poLine.stock_move,
              product_uom_qty: poLineProductUomQty - quantityDone,
              quantityDone: 0,
            }
          : null;
      state.poLines.count =
        poLineProductUomQty > quantityDone ? state.poLines.count : state.poLines.count - 1;
      state.poLines.results = state.poLines.results.filter(
        item =>
          item.id !== action.payload.id ||
          (item.id === action.payload.id && poLineProductUomQty > quantityDone),
      );
    },
    poProcess: (state, action) => {
      state.purchaseOrder = { ...state.purchaseOrder, ...action.payload };
    },
    processETAApprovalRequest: (state, action) => {
      const { purchase_order, ...payload } = action.payload;
      const poLineIndex = state.purchaseOrder.po_line.findIndex(item => item.id === payload.id);
      const updatePOLine = [...state.purchaseOrder.po_line];
      updatePOLine.splice(poLineIndex, 1, payload);
      state.purchaseOrder = {
        ...state.purchaseOrder,
        status: purchase_order.status,
        po_line: updatePOLine,
      };
      state.purchaseOrder = {
        ...state.purchaseOrder,
        po_line: [...state.purchaseOrder.po_line].map(obj => ({
          ...obj,
          approval_request: obj.approval_request.filter(item => item.id !== action.payload.id),
        })),
      };
    },
    resetPOLine: state => {
      state.poLine = initialState.poLine;
    },
    resetPOLines: state => {
      state.poLine = initialState.poLines;
    },
    resetPOLinesParams: state => {
      state.poLinesParams = { limit: state.poLinesParams.limit, offset: 0 };
    },
    resetPoWatchlistLateParams: state => {
      state.poWatchlistLateParams = { limit: state.poWatchlistLateParams.limit, offset: 0 };
    },
    resetPurchaseOrder: state => {
      state.purchaseOrder = null;
    },
    resetPurchaseOrderFilesParams: state => {
      state.purchaseOrderFilesParams = { limit: state.purchaseOrderFilesParams.limit, offset: 0 };
    },
    resetPurchaseOrderPriority: (state, action) => {
      state.purchaseOrderPriority = initialState.purchaseOrderPriority;
    },
    resetPurchaseOrders: state => {
      state.purchaseOrders = initialState.purchaseOrders;
    },
    resetPurchaseOrdersParams: state => {
      state.purchaseOrdersParams = { limit: state.purchaseOrdersParams.limit, offset: 0 };
    },
    resetPurchaseOrderStatus: (state, action) => {
      state.purchaseOrderStatus = initialState.purchaseOrderStatus;
    },
    resetWatchlistIDs: state => {
      state.watchlistIDs = initialState.watchlistIDs;
    },
    setCancelPurchaseOrders: (state, action) => {
      const { ids } = action.payload;
      const idSet = new Set(ids);
      state.purchaseOrders.results.forEach(purchaseOrder => {
        if (idSet.has(purchaseOrder.id)) {
          purchaseOrder.status = "Cancelled";
        }
      });
    },
    setConsolidatePOLines: (state, action) => {
      state.consolidatePOLines = action.payload;
    },
    setPOLine: (state, action) => {
      state.poLine = action.payload;
    },
    setPOLineETA: (state, action) => {
      const updatedPOLines = [...state.purchaseOrder.po_line];
      if (action.payload.po_line) {
        const poLineData = action.payload.po_line;
        const poLineIndex = updatedPOLines.findIndex(item => item.id === poLineData.id);
        updatedPOLines.splice(poLineIndex, 1, poLineData);
        state.purchaseOrder = { ...state.purchaseOrder, po_line: updatedPOLines };
      } else if (action.payload.approval_request) {
        const { purchase_order, ...approvalRequest } = action.payload.approval_request;
        const poLineIndex = updatedPOLines.findIndex(
          item => item.id === approvalRequest.related_object_id,
        );
        const poLine = { ...state.purchaseOrder.po_line[poLineIndex] };
        const newPOLine = {
          ...poLine,
          approval_request: [...poLine.approval_request, approvalRequest],
        };
        state.purchaseOrder.po_line.splice(poLineIndex, 1, newPOLine);
        state.purchaseOrder = {
          ...state.purchaseOrder,
          status: purchase_order.status,
          po_line: state.purchaseOrder.po_line,
        };
      }
    },
    setPOLines: (state, action) => {
      state.poLines = action.payload;
    },
    setPOLinesParams: (state, action) => {
      state.poLinesParams = { ...action.payload };
    },
    setPurchaseOrderBills: (state, action) => {
      if (!state.purchaseOrder) {
        state.purchaseOrder = {};
      }
      state.purchaseOrder.bills = { count: action.payload.count, results: action.payload.results };
    },
    setPurchaseOrderFiles: (state, action) => {
      state.purchaseOrder.files = { count: action.payload.count, results: action.payload.results };
    },
    setPurchaseOrderBillsParams: (state, action) => {
      state.purchaseOrderBillsParams = action.payload;
    },
    setPurchaseOrderFilesParams: (state, action) => {
      state.purchaseOrderFilesParams = action.payload;
    },
    setPurchaseOrderLinesParams: (state, action) => {
      state.purchaseOrderLinesParams = action.payload;
    },
    setPurchaseOrderNotes: (state, action) => {
      if (!state.purchaseOrder) {
        state.purchaseOrder = {};
      }
      state.purchaseOrder.notes = action.payload;
    },
    setPurchaseOrdersParams: (state, action) => {
      state.purchaseOrdersParams = action.payload;
    },
    setWatchlistIDs: (state, action) => {
      state.watchlistIDs = action.payload;
    },
    updateMultiplePurchaseOrders: (state, action) => {
      const orders = action.payload;
      orders.forEach(order => {
        const index = state.purchaseOrders.results.findIndex(item => item.id === order.id);
        if (index !== -1) {
          state.purchaseOrders.results[index] = {
            ...state.purchaseOrders.results[index],
            ...order,
          };
        }
      });
    },
    updateMultiPOLReceiptedQty: (state, action) => {
      const po_lines = action.payload;
      po_lines.forEach(po_line => {
        if (state.purchaseOrder) {
          const index = state.purchaseOrder.po_line.findIndex(item => item.id === po_line.id);
          if (index !== -1) {
            state.purchaseOrder.po_line[index] = {
              ...state.purchaseOrder.po_line[index],
              ...po_line,
            };
          }
        }
        if (state.poLines) {
          const POLineIndex = state.poLines.results.findIndex(item => item.id === po_line.id);
          if (POLineIndex !== -1) {
            state.poLines.results[POLineIndex] = {
              ...state.poLines.results[POLineIndex],
              ...po_line,
            };
          }
        }
      });
    },
    updatePOLine: (state, action) => {
      state.poLine = { ...state.poLine, ...action.payload };
    },
    updatePOLinesParams: (state, action) => {
      state.poLinesParams = { ...state.poLinesParams, ...action.payload };
    },
    updatePOLReceiptedQty: (state, action) => {
      const { id, received_qty, status } = action.payload;
      const index = state.purchaseOrder.po_line.findIndex(item => item.id === id);
      if (index !== -1) {
        state.purchaseOrder.po_line[index] = {
          ...state.purchaseOrder.po_line[index],
          received_qty,
        };
        state.purchaseOrder["status"] = status;
      }
    },
    updatePOWatchlistLateParams: (state, action) => {
      state.poWatchlistLateParams = { ...state.poWatchlistLateParams, ...action.payload };
    },
    updatePurchaseOrderFiles: (state, action) => {
      state.purchaseOrder = state.purchaseOrder || {};
      state.purchaseOrder.files = state.purchaseOrder.files || { count: 0, results: [] };
      state.purchaseOrder.files = { ...state.purchaseOrder.files, ...action.payload };
    },
    updatePurchaseOrdersParams: (state, action) => {
      state.purchaseOrdersParams = { ...state.purchaseOrdersParams, ...action.payload };
    },
    updatePurchaseOrderWatchlist: (state, action) => {
      let data = action.payload;
      state.purchaseOrders.results.forEach(order => {
        if (order.id === data.id) {
          order.watchlist.name = data.watchlist_name;
          order.watchlist.status = data.watchlist_status;
        }
      });
      state.poWatchlist.forEach(item => {
        if (data.watchlist_id === item.id) {
          item.purchase_order.push({ id: data.id });
        }
      });
    },
  },
});

export const {
  addActivityLogToPurchaseOrder,
  addPurchaseOrderFiles,
  addPurchaseOrderNote,
  addPurchaseOrderWatchlist,
  createMultiplePurchaseOrders,
  doVerifyPO,
  editMultiPurchaseOrderLines,
  getDownloadedPurchaseOrderLines,
  getDownloadedPurchaseOrders,
  getPOWatchlistLate,
  getPurchaseOrder,
  getPurchaseOrderFields,
  getPurchaseOrderLineFields,
  getPurchaseOrderLineStatus,
  getPurchaseOrderPOLines,
  getPurchaseOrderPriority,
  getPurchaseOrders,
  getPurchaseOrdersList,
  getPurchaseOrdersSelection,
  getPurchaseOrderStatus,
  getPurchaseOrdersWatchlists,
  getPurchaseOrdersWatchlistsDetail,
  getPurchaseOrderTrack,
  mutatePOLine,
  poProcess,
  processETAApprovalRequest,
  resetPOLine,
  resetPOLines,
  resetPOLinesParams,
  resetPoWatchlistLateParams,
  resetPurchaseOrder,
  resetPurchaseOrderFilesParams,
  resetPurchaseOrderPriority,
  resetPurchaseOrders,
  resetPurchaseOrdersParams,
  resetPurchaseOrderStatus,
  resetWatchlistIDs,
  setCancelPurchaseOrders,
  setConsolidatePOLines,
  setPOLine,
  setPOLineETA,
  setPOLines,
  setPOLinesParams,
  setPurchaseOrderBills,
  setPurchaseOrderBillsParams,
  setPurchaseOrderFiles,
  setPurchaseOrderFilesParams,
  setPurchaseOrderLinesParams,
  setPurchaseOrderNotes,
  setPurchaseOrdersParams,
  setWatchlistIDs,
  updateMultiplePurchaseOrders,
  updateMultiPOLReceiptedQty,
  updatePOLine,
  updatePOLinesParams,
  updatePOLReceiptedQty,
  updatePOWatchlistLateParams,
  updatePurchaseOrderFiles,
  updatePurchaseOrdersParams,
  updatePurchaseOrderWatchlist,
} = purchaseOrderSlice.actions;

export default purchaseOrderSlice.reducer;
