import { App } from "../app/App";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { AuthPage } from "../app/auth/AuthPage";
import { PrivateRoutes } from "./PrivateRoutes";
import { ErrorsPage } from "../app/errors/ErrorsPage";
import { isAuthenticated, logOut } from "../store/slice/authSlice";
import { Registration } from "../app/auth/components/Registration";
import { useAutoLoginQuery } from "../store/apiSlice/authApiSlice";
import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import { Upload } from "../app/compliance/Partner/Manage/Upload";

const AppRoutes = () => {
  const { refetch, isLoading, isFetching } = useAutoLoginQuery();

  const isAuth = useSelector(isAuthenticated);

  const dispatch = useDispatch();

  useEffect(() => {
    const handleStorageChange = event => {
      if (event.key === "authOut" && event.newValue !== null && event.oldValue !== event.newValue) {
        dispatch(logOut());
        localStorage.removeItem("authOut");
      } else if (
        event.key === "authIn" &&
        event.newValue !== null &&
        event.oldValue !== event.newValue
      ) {
        if (!isAuth && !isFetching && !isLoading) refetch();
        localStorage.removeItem("authIn");
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => window.removeEventListener("storage", handleStorageChange);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth]);

  if ([true, false].includes(isAuth)) {
    return (
      <BrowserRouter>
        <Routes>
          <Route element={<App />}>
            <Route path="/join/:token" element={<Registration />} />
            <Route path="/upload/:token" element={<Upload />} />
            <Route pat="error/*" element={<ErrorsPage />} />

            {isAuth ? (
              <>
                <Route path="/*" element={<PrivateRoutes />} />
                <Route index element={<Navigate to="/dashboard" />} />
              </>
            ) : (
              <>
                <Route path="/*" element={<AuthPage />} />
                <Route index element={<Navigate to="/login" />} />
              </>
            )}
          </Route>
        </Routes>
      </BrowserRouter>
    );
  }

  return (
    <div className="w-100 vh-100 d-flex justify-content-center align-items-center">
      {["primary", "secondary", "success", "danger"].map((item, index) => (
        <Spinner key={`${index}_${item}`} animation="grow" variant={item} />
      ))}
    </div>
  );
};

export { AppRoutes };
