import * as XLSX from "xlsx";
import { KTIcon } from "../../../_metronic/helpers";
import { useCreateMultiplePurchaseOrdersMutation } from "../../../store/apiSlice/purchaseOrderApiSlice";
import { useEffect, useState } from "react";
import { useModalView } from "../Provider/Open";
import { useNotification } from "../../../hooks/useNotification";

const UploadPurchaseOrder = () => {
  const { clearCurrentAction, currentAction } = useModalView();

  useEffect(() => {
    const body = document.body;
    if (currentAction === "uploadBulkPurchaseOrder") {
      body.classList.add("modal-open");
    }
    return () => body.classList.remove("modal-open");
  }, [currentAction]);

  if (!(currentAction === "uploadBulkPurchaseOrder")) return;

  return (
    <>
      <div className="d-block fade modal show" aria-modal="true" role="dialog" tabIndex={-1}>
        <div className="modal-dialog modal-dialog-centered mw-600px">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="m-0">Upload File</h2>
              <span onClick={() => clearCurrentAction()}>
                <KTIcon className="cursor-pointer fs-2x text-hover-danger" iconName="cross" />
              </span>
            </div>
            <div className="modal-body scroll-y">
              <UploadFileForm closeUploadModal={clearCurrentAction} />
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </>
  );
};

const UploadFileForm = () => {
  const { clearCurrentAction } = useModalView();
  const notify = useNotification();
  const [uploadedData, setUploadedData] = useState([]);

  const [createMultiplePurchaseOrders, { isLoading }] = useCreateMultiplePurchaseOrdersMutation();

  // Helper function to round to specified precision
  const roundTo = (num, precision = 8) => {
    const factor = Math.pow(10, precision);
    return Math.round(num * factor) / factor;
  };

  /**
   * Handles the file upload event, parses an Excel file containing purchase orders
   * and associated line items, and sets the processed data into application state.
   *
   * The first sheet contains purchase order information, and the second sheet contains
   * line items. This function organizes the line items under their respective purchase orders,
   * optimizes rounding for numeric values, and improves data processing efficiency.
   *
   * @param {Event} event - The file upload event triggered by the user.
   */
  const handleUploadedFile = event => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = async e => {
      const data = e.target.result;

      // Parse the uploaded Excel file
      const workbook = XLSX.read(data, { type: "binary", cellText: false, cellDates: true });

      // Extract first and second sheets
      const [firstSheet, secondSheet] = workbook.SheetNames;
      const firstWorksheet = workbook.Sheets[firstSheet];
      const secondWorksheet = workbook.Sheets[secondSheet];

      // Convert sheets to JSON, setting date format
      const firstSheetJSON = XLSX.utils.sheet_to_json(firstWorksheet, {
        raw: false,
        dateNF: "yyyy-mm-dd",
      });
      const secondSheetJSON = XLSX.utils.sheet_to_json(secondWorksheet, {
        raw: false,
        dateNF: "yyyy-mm-dd",
      });

      // Initialize dictionary to map purchase order lines by purchase order ID
      const poLineMapping = {};

      // Populate dictionary with purchase orders from the first sheet
      firstSheetJSON.forEach(item => {
        poLineMapping[item.name] = { ...item, purchase_order_line_items: [] };
      });

      // Map each line item to its purchase order in the dictionary
      secondSheetJSON.forEach(data => {
        const po = poLineMapping[data.purchase_order];
        if (po) {
          data.ordered_qty = roundTo(data.ordered_qty);
          data.unit_price = roundTo(data.unit_price);
          po.purchase_order_line_items.push(data);
        }
      });

      // Convert dictionary back to array format for setting state
      const mapped_po_line = Object.values(poLineMapping);

      // Update application state with the processed data
      setUploadedData(mapped_po_line);
    };
    reader.readAsArrayBuffer(file);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    try {
      const createdResponse = await createMultiplePurchaseOrders(uploadedData).unwrap();
      notify({ type: "success", message: createdResponse.detail }); // Show success notification
      clearCurrentAction();
    } catch (error) {
      notify({ type: "error", message: error.data?.detail || "Something Went Wrong" });
    }
  };

  return (
    <form className="form" encType="multipart/form-data" onSubmit={handleSubmit}>
      {/* Multiple Upload File Input */}
      <div className="row mb-8">
        <label className="fs-6 fw-medium text-gray mb-2 required" htmlFor="files-input">
          Upload File
        </label>
        <div className="col fv-row">
          <input
            id="files-input"
            className="form-control form-control-solid mb-3 mb-lg-0"
            multiple={true}
            name="files"
            type="file"
            onChange={handleUploadedFile}
          />
        </div>
      </div>

      {/* Submit Button */}
      <div className="text-center">
        <button className="btn btn-primary" disabled={isLoading} type="submit">
          {isLoading ? (
            <span className="d-block indicator-progress">
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          ) : (
            <span className="indicator-label">Submit</span>
          )}
        </button>
      </div>
    </form>
  );
};

export { UploadPurchaseOrder };
