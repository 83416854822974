import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  transactions: { count: 0, results: [] },
  transactionsParams: { limit: 10, offset: 0 },
};

const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    setTransactions: (state, action) => {
      state.transactions = action.payload;
    },
    resetTransactions: state => {
      state.transactions = initialState.transactions;
    },
    resetTransactionsParams: state => {
      state.transactionsParams = initialState.transactionsParams;
    },
    setTransactionsParams: (state, action) => {
      state.transactionsParams = action.payload;
    },
  },
});

export const {
  setTransactions,
  resetTransactions,
  resetTransactionsParams,
  setTransactionsParams,
} = reportSlice.actions;
export default reportSlice.reducer;
