import { AsideDefault } from "./components/aside/AsideDefault";
import { Content } from "./components/Content";
import { Footer } from "./components/Footer";
import { HeaderWrapper } from "./components/header/HeaderWrapper";
import { MenuComponent } from "../assets/ts/components";
import { Outlet, useLocation } from "react-router-dom";
import { PageDataProvider } from "./core";
import { ScrollTop } from "./components/ScrollTop";
import { useEffect } from "react";

const MasterLayout = () => {
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);
  }, [location.key]);

  return (
    <PageDataProvider>
      <div className="page d-flex flex-row flex-column-fluid">
        <AsideDefault />
        <div className="wrapper d-flex flex-column flex-row-fluid body_wrapper" id="kt_wrapper">
          <HeaderWrapper />
          <div id="kt_content" className="content d-flex flex-column flex-column-fluid">
            <div className="post d-flex flex-column-fluid" id="kt_post">
              <Content>
                <Outlet />
              </Content>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <ScrollTop />
    </PageDataProvider>
  );
};

export { MasterLayout };
