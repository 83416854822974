import clsx from "clsx";
import moment from "moment-timezone";

export const timeStampInCurrentTZ = timestamp => moment.tz(timestamp, moment.tz.guess());

/**
 * Formats a given amount to a specified number of decimal places.
 * Allows toggling between rounded and precise values.
 * If the input is not a valid number, returns null.
 *
 * @param {number|string} amount - The amount to format.
 * @param {number} decimalPlaces - Number of decimal places (default is 2).
 * @param {boolean} round - Whether to round the number (default is false).
 * @returns {number|null} The formatted amount or null if input is invalid.
 */
export const formatAmount = (amount, decimalPlaces = 2, round = false) => {
  // Check if the amount is a number or can be converted to a number
  const numericAmount = Number(amount);
  if (isNaN(numericAmount)) {
    return null; // Return null for invalid inputs
  }

  if (round) {
    // If round is true, round to the nearest decimal place
    const factor = Math.pow(10, decimalPlaces);
    return Math.round(numericAmount * factor) / factor;
  } else {
    // If round is false, simply format to the specified decimal places
    return Number(numericAmount).toFixed(decimalPlaces);
  }
};

/**
 * Retrieves a nested value from an object based on a given path.
 *
 * @param {Object} obj - The object to retrieve the value from.
 * @param {string} path - A dot-separated string specifying the path to the nested value.
 * @returns {*} - The value found at the specified path, or undefined if the path is not valid.
 *
 * @example
 * const obj = { a: { b: { c: 1 } } };
 * const value = getNestedValue(obj, 'a.b.c'); // returns 1
 * const invalidValue = getNestedValue(obj, 'a.b.x'); // returns undefined
 */
export const getNestedValue = (obj, path) => {
  // Ensure the object is not null or undefined
  if (!obj) return undefined;

  return path.split(".").reduce((acc, part) => {
    // Check if the accumulator is an object and has the key
    return typeof acc === "object" && acc !== null ? acc[part] : undefined;
  }, obj);
};

export const consolidateErrors = errorsArray => {
  const errors = {};

  errorsArray.forEach(errorObj => {
    // Iterate over each object in the array
    Object.keys(errorObj).forEach(key => {
      // Check if the key already exists in the errors object
      if (!errors[key]) {
        errors[key] = new Set(); // Use a Set to ensure uniqueness
      }
      // Add the error message(s) to the Set
      errorObj[key].forEach(message => {
        errors[key].add(message);
      });
    });
  });

  // Convert Set back to array for easier usage in most UI frameworks
  Object.keys(errors).forEach(key => {
    errors[key] = Array.from(errors[key]);
  });

  return errors;
};

export const randomColor = () => {
  const colors = ["success", "secondary", "info", "warning", "primary", "danger"];

  // Generate a random color index
  const randomColorIndex = Math.floor(Math.random() * colors.length);

  // Use the random index to select a random element
  return colors[randomColorIndex];
};

function isSameDay(date1, date2) {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}

function isYesterday(date, now) {
  const yesterday = new Date(now);
  yesterday.setDate(now.getDate() - 1);
  return isSameDay(date, yesterday);
}

export function formatChatDate(dateString) {
  const date = new Date(dateString);
  const now = new Date();

  const timeOptions = { hour: "numeric", minute: "numeric", hour12: true };
  const dateOptions = { month: "short", day: "numeric", ...timeOptions };
  const fullDateOptions = { year: "numeric", ...dateOptions };

  if (isSameDay(date, now)) {
    // Today: show time only
    return date.toLocaleTimeString("en-US", timeOptions);
  } else if (isYesterday(date, now)) {
    // Yesterday: show 'Yesterday' with time
    const timeString = date.toLocaleTimeString("en-US", timeOptions);
    return `Yesterday, ${timeString}`;
  } else if (date < now) {
    // Before yesterday: show date and time
    return date.toLocaleDateString("en-US", dateOptions);
  } else {
    // Last year: show full date and time with year
    return date.toLocaleDateString("en-US", fullDateOptions);
  }
}

export function getTableColumns(columns, index) {
  if (!Array.isArray(columns)) return [];

  const addIndex = columns[0].id === "selection" && index > 0 ? 1 : 0;

  return columns.map((column, i) => {
    const shouldFix = i < index + addIndex;

    return {
      ...column,
      className: clsx(column.className, column.fixedPositionClass, {
        "bg-body position-sticky z-i": shouldFix,
      }),
    };
  });
}
