import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  collabRoom: {},
  documents: [],
  members: [],
  roomData: [],
};

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    addCollabRoomMsgDown: (state, action) => {
      state.collabRoom.messages = [...state.collabRoom.messages, action.payload];
    },
    addMembers: (state, action) => {
      state.members = [...state.members, action.payload];
    },
    getRoomData: (state, action) => {
      state.roomData = action.payload;
    },
    resetCollabRoom: state => {
      state.collabRoom = initialState.collabRoom;
    },
    resetRoomData: state => {
      state.roomData = [];
    },
    setCollabRoom: (state, action) => {
      state.collabRoom = action.payload;
    },
    setDocuments: (state, action) => {
      state.documents = action.payload;
    },
    setMembers: (state, action) => {
      state.members = action.payload;
    },
    updateCollabRoomMessageAsSeen: (state, action) => {
      const index = state.collabRoom.messages.findIndex(item => item.id === action.payload.id);
      if (index !== -1) {
        state.collabRoom.messages[index].seen_by = [
          ...state.collabRoom.messages[index].seen_by,
          action.payload.user,
        ];
      }
    },
  },
});

export const {
  addCollabRoomMsgDown,
  addMembers,
  getRoomData,
  resetCollabRoom,
  resetRoomData,
  setCollabRoom,
  setDocuments,
  setMembers,
  updateCollabRoomMessageAsSeen,
} = chatSlice.actions;

export default chatSlice.reducer;

export const userRoomData = state => state.chat.roomData;
