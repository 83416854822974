import { apiSlice } from "./apiSlice";
import {
  markAllAsRead,
  setNotificationPreferences,
  setNotifications,
  updateNotification,
  updateNotificationPreferences,
} from "../slice/miscSlice";

export const notificationApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    createNotificationPreferenceGroup: builder.mutation({
      query: data => ({ body: data, method: "POST", url: "/api_ntf/preference_mutate/" }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(updateNotificationPreferences(result.data)))
          .catch(error => {});
      },
    }),
    getNotificationPreferences: builder.query({
      query: () => "/api_ntf/preference/",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(setNotificationPreferences(result.data)))
          .catch(error => {});
      },
    }),
    getNotifications: builder.query({
      query: () => `/api_ntf/user/`,
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => dispatch(setNotifications(result.data))).catch(error => {});
      },
    }),
    markAllNotificationsAsRead: builder.mutation({
      query: () => ({ body: {}, method: "PATCH", url: `/api_ntf/user/mark_all_as_read/` }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => dispatch(markAllAsRead())).catch(error => {});
      },
    }),
    mutateNotificationPreferenceGroup: builder.mutation({
      query: data => ({
        body: data,
        method: "POST",
        url: "/api_ntf/preference_mutate/create_forge/",
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(updateNotificationPreferences(result.data)))
          .catch(error => {});
      },
    }),
    patchNotificationToIsread: builder.mutation({
      query: ({ id, ...data }) => ({ body: data, method: "PATCH", url: `/api_ntf/user/${id}/` }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => dispatch(updateNotification(result.data))).catch(error => {});
      },
    }),
    updateNotificationPreferenceGroup: builder.mutation({
      query: ({ id, ...data }) => ({
        body: data,
        method: "PATCH",
        url: `/api_ntf/preference_mutate/${id}/`,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(updateNotificationPreferences(result.data)))
          .catch(error => {});
      },
    }),
  }),
});

export const {
  useCreateNotificationPreferenceGroupMutation,
  useGetNotificationPreferencesQuery,
  useGetNotificationsQuery,
  useMarkAllNotificationsAsReadMutation,
  useMutateNotificationPreferenceGroupMutation,
  usePatchNotificationToIsreadMutation,
  useUpdateNotificationPreferenceGroupMutation,
} = notificationApiSlice;
