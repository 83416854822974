import clsx from "clsx";
import noUiSlider from "nouislider";
import Notification from "../../../../app/notification";
import { DefaultTitle } from "./page-title/DefaultTitle";
import { ThemeModeSwitcher } from "../../../partials";
import { useEffect } from "react";
import { useLayout } from "../../core";
import GlobalMenu from "../../../../app/globalMenu";

const HeaderToolbar = () => {
  const { classes } = useLayout();

  useEffect(() => {
    const slider = document.querySelector("#kt_toolbar_slider");
    const rangeSliderValueElement = document.querySelector("#kt_toolbar_slider_value");

    if (!slider) {
      return;
    }

    // Check if the slider is already initialized
    if (slider.noUiSlider) {
      slider.noUiSlider.destroy();
    }

    noUiSlider.create(slider, {
      start: [5],
      connect: [true, false],
      step: 1,
      range: {
        min: [1],
        max: [10],
      },
    });

    slider.noUiSlider?.on("update", function (values, handle) {
      if (!rangeSliderValueElement) {
        return;
      }

      rangeSliderValueElement.innerHTML = parseInt(values[handle]).toFixed(1);
    });

    // Cleanup function
    return () => {
      slider.noUiSlider?.destroy();
    };
  }, []);

  return (
    <div className="toolbar d-flex align-items-stretch">
      <div
        className={clsx(
          classes.headerContainer.join(" "),
          "d-flex align-items-middle justify-content-between py-4 py-md-6",
        )}
      >
        <DefaultTitle />

        <div className="d-flex align-items-center gap-3 gap-md-2">
          <GlobalMenu />
          <ThemeModeSwitcher toggleBtnClass="btn btn-sm btn-icon btn-icon-muted btn-active-icon-primary" />
          <Notification />
        </div>
      </div>
    </div>
  );
};

export { HeaderToolbar };
