import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  complianceTable: [],
  complianceParams: { limit: 10, offset: 0 },

  partnerComplianceFilesParams: { limit: 10, offset: 0 },
  reminders: [],
};

const complianceSlice = createSlice({
  name: "compliance",
  initialState,
  reducers: {
    resetComplianceParams: state => {
      state.complianceParams = { limit: state.complianceParams.limit, offset: 0 };
    },
    resetPartnerComplianceFilesParams: state => {
      state.partnerComplianceFilesParams = {
        limit: state.partnerComplianceFilesParams.limit,
        offset: 0,
      };
    },
    resetReminders: state => {
      state.reminders = initialState.reminders;
    },
    setcomplianceFiles: (state, action) => {
      state.complianceTable = action.payload;
    },
    setComplianceParams: (state, action) => {
      state.complianceParams = action.payload;
    },
    setPartnerComplianceFilesParams: (state, action) => {
      state.partnerComplianceFilesParams = action.payload;
    },
    setReminders: (state, action) => {
      state.reminders = action.payload;
    },
    updateReminderList: (state, action) => {
      state.reminders = [...state.reminders, action.payload];
    },
    updateComplianceParams: (state, action) => {
      state.complianceParams = { ...state.complianceParams, ...action.payload };
    },
    updatePartnerComplianceFilesParams: (state, action) => {
      state.partnerComplianceFilesParams = {
        ...state.partnerComplianceFilesParams,
        ...action.payload,
      };
    },
  },
});

export const {
  resetComplianceParams,
  resetPartnerComplianceFilesParams,
  resetReminders,
  setcomplianceFiles,
  setComplianceParams,
  setPartnerComplianceFilesParams,
  setReminders,
  updateReminderList,
  updateComplianceParams,
  updatePartnerComplianceFilesParams,
} = complianceSlice.actions;

export default complianceSlice.reducer;
