import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: null,
  user: null,
  resetPasswordEmail: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authFailed: state => {
      state.isAuthenticated = false;
      state.user = null;
    },
    authSuccess: (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload;
    },
    isPasswordCreated: state => {
      state.user = { ...state.user, has_password: true };
    },
    logOut: state => {
      state.isAuthenticated = false;
      state.user = null;
    },
    updatePerPageParams: (state, action) => {
      const { limit, precision } = action.payload;
      state.user = { ...state.user, limit: limit, precision: precision };
    },
    addEmailInResetPassword: (state, action) => {
      state.resetPasswordEmail = action.payload;
    },
  },
});

export const {
  authFailed,
  authSuccess,
  isPasswordCreated,
  logOut,
  updatePerPageParams,
  addEmailInResetPassword,
} = authSlice.actions;

export default authSlice.reducer;

export const authUser = state => state.auth.user;
export const isAuthenticated = state => state.auth.isAuthenticated;
