import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  poReceiptReconciliation: null,
  poReceiptReconciliations: { count: 0, results: [] },
  poReceiptReconciliationsParams: { limit: 10, offset: 0, type: "reconciliation" },

  poReceiptRequest: null,
  poReceiptRequests: { count: 0, results: [] },
  poReceiptRequestsParams: { limit: 10, offset: 0, order: "purchase", type: "receipt" },

  tfOrdReceiptRequest: null,
  tfOrdReceiptRequests: { count: 0, results: [] },
  tfOrdReceiptRequestsParams: { limit: 10, offset: 0, order: "transfer", type: "receipt" },

  receiptDateRangeSelection: { startDate: "", endDate: "", reset: false, key: "" },

  receiptTransactionHistory: { count: 0, results: [] },
  receiptTransactionHistoryParams: { limit: 10, offset: 0 },
};

const receiptSlice = createSlice({
  name: "receipt",
  initialState,
  reducers: {
    approvePOReceiptRequests: (state, action) => {
      state.poReceiptRequests = {
        count: state.poReceiptRequests.count - action.payload.length,
        results: state.poReceiptRequests.results.filter(item => action.payload.includes(item.id)),
      };
    },
    approveTfOrdReceiptRequests: (state, action) => {
      state.tfOrdReceiptRequests = {
        count: state.tfOrdReceiptRequests.count - action.payload.length,
        results: state.tfOrdReceiptRequests.results.filter(item =>
          action.payload.includes(item.id),
        ),
      };
    },
    getReceiptTransactionHistory: (state, action) => {
      const data = action.payload;
      state.receiptTransactionHistory = { count: data.count, results: data.results };
    },
    managePOReceiptRequest: (state, action) => {
      state.poReceiptRequests = {
        ...state.poReceiptRequests,
        count: state.poReceiptRequests.count - 1,
        results: state.poReceiptRequests.results.filter(item => item.id !== action.payload),
      };
    },
    manageTfOrdReceiptRequest: (state, action) => {
      state.tfOrdReceiptRequests = {
        ...state.tfOrdReceiptRequests,
        count: state.tfOrdReceiptRequests.count - 1,
        results: state.tfOrdReceiptRequests.results.filter(item => item.id !== action.payload),
      };
    },
    removePOReceiptReconciliation: (state, action) => {
      state.poReceiptReconciliations = {
        ...state.poReceiptReconciliations,
        count: state.poReceiptReconciliations.count - 1,
        results: state.poReceiptReconciliations.results.filter(
          item => item.id !== action.payload.id,
        ),
      };
    },
    resetPOReceiptReconciliation: state => {
      state.poReceiptReconciliation = initialState.poReceiptReconciliation;
    },
    resetPOReceiptReconciliations: state => {
      state.poReceiptReconciliations = initialState.poReceiptReconciliations;
    },
    resetPOReceiptReconciliationsParams: state => {
      state.poReceiptReconciliationsParams = {
        limit: state.poReceiptReconciliationsParams.limit,
        offset: 0,
        type: "reconciliation",
      };
    },
    resetPOReceiptRequest: state => {
      state.poReceiptRequest = initialState.poReceiptRequest;
    },
    resetPOReceiptRequestParams: state => {
      state.poReceiptRequestsParams = {
        limit: state.poReceiptRequestsParams.limit,
        offset: 0,
        order: "purchase",
        type: "receipt",
      };
    },
    resetPOReceiptRequests: state => {
      state.poReceiptRequests = initialState.poReceiptRequests;
    },
    resetReceiptTransactionHistoryParams: state => {
      state.receiptTransactionHistoryParams = {
        limit: state.receiptTransactionHistoryParams.limit,
        offset: 0,
      };
    },
    resetTfOrdReceiptRequest: state => {
      state.tfOrdReceiptRequest = initialState.tfOrdReceiptRequest;
    },
    resetTfOrdReceiptRequestParams: state => {
      state.tfOrdReceiptRequestsParams = {
        limit: state.tfOrdReceiptRequestsParams.limit,
        offset: 0,
        order: "transfer",
        type: "receipt",
      };
    },
    resetTfOrdReceiptRequests: state => {
      state.tfOrdReceiptRequests = initialState.tfOrdReceiptRequests;
    },
    setPOReceiptReconciliation: (state, action) => {
      state.poReceiptReconciliation = action.payload;
    },
    setPOReceiptReconciliations: (state, action) => {
      state.poReceiptReconciliations = action.payload;
    },
    setPOReceiptRequest: (state, action) => {
      state.poReceiptRequest = action.payload;
    },
    setPOReceiptRequests: (state, action) => {
      state.poReceiptRequests = action.payload;
    },
    setPOReceiptRequestsParams: (state, action) => {
      state.poReceiptRequestsParams = { ...state.poReceiptRequestsParams, ...action.payload };
    },
    setReceiptDateSelectionRange: (state, action) => {
      state.receiptDateRangeSelection = action.payload;
    },
    setReceiptTransactionHistoryParams: (state, action) => {
      state.receiptTransactionHistoryParams = action.payload;
    },
    setTfOrdReceiptRequest: (state, action) => {
      state.tfOrdReceiptRequest = action.payload;
    },
    setTfOrdReceiptRequests: (state, action) => {
      state.tfOrdReceiptRequests = action.payload;
    },
    setTfOrdReceiptRequestsParams: (state, action) => {
      state.tfOrdReceiptRequestsParams = { ...state.tfOrdReceiptRequestsParams, ...action.payload };
    },
    updatePOReceiptReconciliationsParams: (state, action) => {
      state.poReceiptReconciliationsParams = {
        ...state.poReceiptReconciliationsParams,
        ...action.payload,
      };
    },
    updatePOReceiptRequest: (state, action) => {
      state.poReceiptRequest = { ...state.poReceiptRequest, ...action.payload };
    },
    updatePOReceiptRequests: (state, action) => {
      const { id, ...rest } = action.payload;
      const index = state.poReceiptRequests.results.findIndex(item => item.id === id);
      if (index !== -1) {
        state.poReceiptRequests.results[index] = {
          ...state.poReceiptRequests.results[index],
          ...rest,
        };
      }
    },
    updatePOReceiptRequestsParams: (state, action) => {
      state.poReceiptRequestsParams = { ...state.poReceiptRequestsParams, ...action.payload };
    },
    updateReceiptTransactionHistoryParams: (state, action) => {
      state.receiptTransactionHistoryParams = {
        ...state.receiptTransactionHistoryParams,
        ...action.payload,
      };
    },
    updateTfOrdReceiptRequest: (state, action) => {
      state.tfOrdReceiptRequest = { ...state.tfOrdReceiptRequest, ...action.payload };
    },
    updateTfOrdReceiptRequests: (state, action) => {
      const { id, ...rest } = action.payload;
      const index = state.tfOrdReceiptRequests.results.findIndex(item => item.id === id);
      if (index !== -1) {
        state.tfOrdReceiptRequests.results[index] = {
          ...state.tfOrdReceiptRequests.results[index],
          ...rest,
        };
      }
    },
    updateTfOrdReceiptRequestsParams: (state, action) => {
      state.tfOrdReceiptRequestsParams = { ...state.tfOrdReceiptRequestsParams, ...action.payload };
    },
  },
});

export const {
  approvePOReceiptRequests,
  approveTfOrdReceiptRequests,
  getReceiptTransactionHistory,
  managePOReceiptRequest,
  manageTfOrdReceiptRequest,
  removePOReceiptReconciliation,
  resetPOReceiptReconciliation,
  resetPOReceiptReconciliations,
  resetPOReceiptReconciliationsParams,
  resetPOReceiptRequest,
  resetPOReceiptRequestParams,
  resetPOReceiptRequests,
  resetReceiptTransactionHistoryParams,
  resetTfOrdReceiptRequest,
  resetTfOrdReceiptRequestParams,
  resetTfOrdReceiptRequests,
  setPOReceiptReconciliation,
  setPOReceiptReconciliations,
  setPOReceiptRequest,
  setPOReceiptRequests,
  setPOReceiptRequestsParams,
  setReceiptDateSelectionRange,
  setReceiptTransactionHistoryParams,
  setTfOrdReceiptRequest,
  setTfOrdReceiptRequests,
  setTfOrdReceiptRequestsParams,
  updatePOReceiptReconciliationsParams,
  updatePOReceiptRequest,
  updatePOReceiptRequests,
  updatePOReceiptRequestsParams,
  updateReceiptTransactionHistoryParams,
  updateTfOrdReceiptRequest,
  updateTfOrdReceiptRequests,
  updateTfOrdReceiptRequestsParams,
} = receiptSlice.actions;

export default receiptSlice.reducer;
