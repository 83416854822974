/* eslint-disable jsx-a11y/anchor-is-valid */
import webSocketService from "../../helper/WebSocketService";
import { KTIcon } from "../../_metronic/helpers";
import { NotificationDetail } from "./Details";
import { setReceivedMessage } from "../../store/slice/webSocketSlice";
import { useEffect } from "react";
import { useGetNotificationsQuery } from "../../store/apiSlice/notificationApiSlice";
import { useSelector, useDispatch } from "react-redux";

const Notification = () => {
  const { id: authUserID } = useSelector(state => state.auth.user) || {};
  const notifications = useSelector(state => state.misc.notifications);

  // Calculate the unread notifications length
  const unreadNotificationsCount = notifications.filter(item => !item.is_read).length;

  useGetNotificationsQuery(authUserID, { refetchOnMountOrArgChange: true });

  const dispatch = useDispatch();

  useEffect(() => {
    webSocketService.createWebSocket();
    return () => {
      webSocketService.closeWebSocket();
      dispatch(setReceivedMessage(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <a
        className="btn btn-sm btn-icon btn-icon-muted btn-active-icon-primary"
        data-kt-menu-attach="parent"
        data-kt-menu-placement="bottom-end"
        data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
        href="#"
      >
        <div className="position-relative">
          <KTIcon className="fs-1" iconName="notification" />
          {unreadNotificationsCount > 0 && (
            <span className="badge badge-danger position-absolute rounded-pill start-100 top-0 translate-middle">
              {unreadNotificationsCount}
            </span>
          )}
        </div>
      </a>
      <NotificationDetail
        notifications={notifications}
        unreadNotificationsCount={unreadNotificationsCount}
      />
    </div>
  );
};

export default Notification;
