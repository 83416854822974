import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  forecasts: [],
  forecastsParams: { limit: 10, offset: 0 },
  downloadedForecast: [],

  stockOnHand: [],
  stockOnHandParams: { limit: 10, offset: 0 },
  downloadedStockOnhand: [],

  stockMethods: [],
  stockStatus: [],
  stockFields: [],

  stockLines: { count: 0, results: [] },
  stockLinesParams: { limit: 10, offset: 0 },
  stockLineStatus: [],

  warehouseCosting: [],
  warehouseCostingParams: { limit: 10, offset: 0 },
  downloadedWarehouseCosting: [],

  warehouseInventory: [],
  warehouseLanded: [],

  warehouseCostingRange: [],
  warehouseCostingRangeParams: { limit: 10, offset: 0 },
  downloadedWarehouseCostingRange: [],

  warehouseCostingFields: [],
  warehouseCostingRangeFields: [],
};

const warehouseSlice = createSlice({
  name: "warehouse",
  initialState,
  reducers: {
    getForecasts: (state, action) => {
      state.forecasts = action.payload;
    },
    resetForecasts: (state, action) => {
      state.forecasts = initialState.forecasts;
    },
    createForecast: (state, action) => {
      state.forecasts.count += action.payload.length;
      state.forecasts.results = [...state.forecasts.results, action.payload];
    },
    createMultipleForecasts: (state, action) => {
      state.forecasts.count += action.payload.length;
      state.forecasts.results = [...state.forecasts.results, ...action.payload];
    },
    updateForecast: (state, action) => {
      const index = state.forecasts.results.findIndex(item => item.id === action.payload.id);
      if (index !== -1) {
        state.forecasts.results[index] = {
          ...state.forecasts.results[index],
          from_date: action.payload.from_date,
          to_date: action.payload.to_date,
          product: action.payload.product,
          qty: action.payload.qty,
          warehouse: action.payload.warehouse,
        };
      }
    },
    updateMultipleForecast: (state, action) => {
      const forecasts = action.payload;
      forecasts.forEach(forecast => {
        const index = state.forecasts.results.findIndex(item => item.id === forecast.id);
        if (index !== -1) {
          state.forecasts.results[index] = {
            ...state.forecasts.results[index],
            ...forecast,
          };
        }
      });
    },
    deleteForecasts: (state, action) => {
      const forecasts = action.payload;
      state.forecasts.count = state.forecasts.count - action.payload.length;
      forecasts.forEach(forecast => {
        state.forecasts.results = state.forecasts?.results?.filter(item => item.id !== forecast);
      });
    },
    setForecastsParams: (state, action) => {
      state.forecastsParams = { ...action.payload };
    },
    resetForecastsParams: state => {
      state.forecastsParams = { limit: state.forecastsParams.limit, offset: 0 };
    },
    getStockOnHand: (state, action) => {
      state.stockOnHand = action.payload;
    },
    resetStockOnHand: (state, action) => {
      state.stockOnHand = initialState.stockOnHand;
    },
    setStockLines: (state, action) => {
      state.stockLines = action.payload;
    },
    resetStockLines: (state, action) => {
      state.stockLines = initialState.stockLines;
    },
    createStockOnHand: (state, action) => {
      state.stockOnHand.count += action.payload.length;
      state.stockOnHand.results = [...state.stockOnHand.results, action.payload];
    },
    createMultipleStockOnHand: (state, action) => {
      state.stockOnHand.count += action.payload.length;
      state.stockOnHand.results = [...state.stockOnHand.results, ...action.payload];
    },
    updateStockOnHand: (state, action) => {
      const index = state.stockOnHand.results.findIndex(item => item.id === action.payload.id);
      if (index !== -1) {
        state.stockOnHand.results[index] = {
          ...state.stockOnHand.results[index],
          product: action.payload.product,
          warehouse: action.payload.warehouse,
          method: action.payload.method,
          allocated: action.payload.allocated,
          average_cost: action.payload.average_cost,
          nsp: action.payload.nsp,
          rejected: action.payload.rejected,
          reserved: action.payload.reserved,
          sellable: action.payload.sellable,
          unsellable: action.payload.unsellable,
          total: action.payload.total,
          status: action.payload.status,
        };
      }
    },
    updateMultipleStockOnHand: (state, action) => {
      const stocks = action.payload;
      stocks.forEach(stock => {
        const index = state.stockOnHand.results.findIndex(item => item.id === stock.id);
        if (index !== -1) {
          state.stockOnHand.results[index] = {
            ...state.stockOnHand.results[index],
            ...stock,
          };
        }
      });
    },
    setStockOnHandParams: (state, action) => {
      state.stockOnHandParams = { ...action.payload };
    },
    resetStockOnHandParams: state => {
      state.stockOnHandParams = { limit: state.stockOnHandParams.limit, offset: 0 };
    },
    setStockLinesParams: (state, action) => {
      state.stockLinesParams = { ...action.payload };
    },
    getStockMethods: (state, action) => {
      state.stockMethods = action.payload;
    },
    resetStockMethods: state => {
      state.stockMethods = initialState.stockMethods;
    },
    getStockStatus: (state, action) => {
      state.stockStatus = action.payload;
    },
    resetStockStatus: state => {
      state.stockStatus = initialState.stockStatus;
    },
    getStockFields: (state, action) => {
      state.stockFields = action.payload;
    },
    getWarehouseCosting: (state, action) => {
      state.warehouseCosting = action.payload;
    },
    resetWarehouseCosting: (state, action) => {
      state.warehouseCosting = initialState.warehouseCosting;
    },
    createWarehouseCosting: (state, action) => {
      state.warehouseCosting.count += action.payload.length;
      state.warehouseCosting.results = [...state.warehouseCosting.results, action.payload];
    },
    createMultipleWarehouseCosting: (state, action) => {
      state.warehouseCosting.count += action.payload.length;
      state.warehouseCosting.results = [...state.warehouseCosting.results, ...action.payload];
    },
    updateWarehouseCosting: (state, action) => {
      const index = state.warehouseCosting.results.findIndex(item => item.id === action.payload.id);
      if (index !== -1) {
        state.warehouseCosting.results[index] = {
          ...state.warehouseCosting.results[index],
          product: action.payload.product,
          warehouse: action.payload.warehouse,
          buying_currency: action.payload.buying_currency,
          costing_decimal_places: action.payload.costing_decimal_places,
          origin_counrty: action.payload.origin_counrty,
          iam: action.payload.iam,
          landed_cost_type: action.payload.landed_cost_type,
          selling_currency: action.payload.selling_currency,
        };
      }
    },
    updateMultipleWarehouseCosting: (state, action) => {
      const costings = action.payload;
      costings.forEach(costing => {
        const index = state.warehouseCosting.results.findIndex(item => item.id === costing.id);
        if (index !== -1) {
          state.warehouseCosting.results[index] = {
            ...state.warehouseCosting.results[index],
            ...costing,
          };
        }
      });
    },
    setWarehouseCostingParams: (state, action) => {
      state.warehouseCostingParams = { ...action.payload };
    },
    resetWarehouseCostingParams: state => {
      state.warehouseCostingParams = { limit: state.warehouseCostingParams.limit, offset: 0 };
    },
    getWarehouseInventory: (state, action) => {
      state.warehouseInventory = action.payload;
    },
    resetWarehouseInventory: state => {
      state.warehouseInventory = initialState.warehouseInventory;
    },
    getWarehouseLanded: (state, action) => {
      state.warehouseLanded = action.payload;
    },
    resetWarehouseLanded: state => {
      state.warehouseLanded = initialState.warehouseLanded;
    },
    getWarehouseCostingRange: (state, action) => {
      state.warehouseCostingRange = action.payload;
    },
    resetWarehouseCostingRange: (state, action) => {
      state.warehouseCostingRange = initialState.warehouseCostingRange;
    },
    createWarehouseCostingRange: (state, action) => {
      state.warehouseCostingRange.count += action.payload.length;
      state.warehouseCostingRange.results = [
        ...state.warehouseCostingRange.results,
        action.payload,
      ];
    },
    createMultipleWarehouseCostingRange: (state, action) => {
      state.warehouseCostingRange.count += action.payload.length;
      state.warehouseCostingRange.results = [
        ...state.warehouseCostingRange.results,
        ...action.payload,
      ];
    },
    updateWarehouseCostingRange: (state, action) => {
      const index = state.warehouseCostingRange.results.findIndex(
        item => item.id === action.payload.id,
      );
      if (index !== -1) {
        state.warehouseCostingRange.results[index] = {
          ...state.warehouseCostingRange.results[index],
          from_qty: action.payload.from_qty,
          price: action.payload.price,
          to_qty: action.payload.to_qty,
          warehouse_costing: action.payload.warehouse_costing,
        };
      }
    },
    updateMultipleWarehouseCostingRange: (state, action) => {
      const costingRanges = action.payload;
      costingRanges.forEach(costingRange => {
        const index = state.warehouseCostingRange.results.findIndex(
          item => item.id === costingRange.id,
        );
        if (index !== -1) {
          state.warehouseCostingRange.results[index] = {
            ...state.warehouseCostingRange.results[index],
            ...costingRange,
          };
        }
      });
    },
    setWarehouseCostingRangeParams: (state, action) => {
      state.warehouseCostingRangeParams = action.payload;
    },
    resetWarehouseCostingRangeParams: state => {
      state.warehouseCostingRangeParams = {
        limit: state.warehouseCostingRangeParams.limit,
        offset: 0,
      };
    },
    getWarehouseCostingFields: (state, action) => {
      state.warehouseCostingFields = action.payload;
    },
    getWarehouseCostingRangeFields: (state, action) => {
      state.warehouseCostingRangeFields = action.payload;
    },
    getDownloadedForecast: (state, action) => {
      state.downloadedForecast = action.payload;
    },
    getDownloadedStockOnHand: (state, action) => {
      state.downloadedStockOnhand = action.payload;
    },
    getDownloadedWarehouseCosting: (state, action) => {
      state.downloadedWarehouseCosting = action.payload;
    },
    getDownloadedWarehouseCostingRange: (state, action) => {
      state.downloadedWarehouseCostingRange = action.payload;
    },
    setStockLineStatus: (state, action) => {
      state.stockLineStatus = action.payload;
    },
    resetStockLinesStatus: state => {
      state.stockLineStatus = initialState.stockLineStatus;
    },
  },
});

export const {
  getForecasts,
  resetForecasts,
  createForecast,
  createMultipleForecasts,
  updateForecast,
  updateMultipleForecast,
  deleteForecasts,
  setForecastsParams,
  resetForecastsParams,
  getStockOnHand,
  resetStockOnHand,
  setStockLines,
  resetStockLines,
  createStockOnHand,
  createMultipleStockOnHand,
  updateStockOnHand,
  updateMultipleStockOnHand,
  setStockOnHandParams,
  resetStockOnHandParams,
  setStockLinesParams,
  getStockMethods,
  resetStockMethods,
  getStockStatus,
  resetStockStatus,
  getStockFields,
  getWarehouseCosting,
  resetWarehouseCosting,
  createWarehouseCosting,
  createMultipleWarehouseCosting,
  updateWarehouseCosting,
  updateMultipleWarehouseCosting,
  setWarehouseCostingParams,
  resetWarehouseCostingParams,
  getWarehouseInventory,
  resetWarehouseInventory,
  getWarehouseLanded,
  resetWarehouseLanded,
  getWarehouseCostingRange,
  resetWarehouseCostingRange,
  createWarehouseCostingRange,
  createMultipleWarehouseCostingRange,
  updateWarehouseCostingRange,
  updateMultipleWarehouseCostingRange,
  setWarehouseCostingRangeParams,
  resetWarehouseCostingRangeParams,
  getWarehouseCostingFields,
  getWarehouseCostingRangeFields,
  getDownloadedForecast,
  getDownloadedStockOnHand,
  getDownloadedWarehouseCosting,
  getDownloadedWarehouseCostingRange,
  setStockLineStatus,
  resetStockLineStatus,
} = warehouseSlice.actions;

export default warehouseSlice.reducer;
