import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  bill: null,

  billChargeTypesSelection: [],

  currencies: [],

  invoice: null,
  invoices: [],
  invoicesParams: { limit: 10, offset: 0 },

  invoiceIDs: [],

  invoicesHistoryList: { count: 0, results: [] },
  invoicesHistoryParams: { limit: 10, offset: 0 },

  invoicesList: { count: 0, results: [] },

  pendingInvoicesList: { count: 0, results: [] },
  pendingInvoicesParams: { limit: 10, offset: 0 },
};

const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  reducers: {
    deleteInvoice: (state, action) => {
      state.invoices = {
        count: state.invoices.count - 1,
        results: state.invoices.results.filter(item => item.id !== action.payload),
      };

      state.pendingInvoicesList = {
        count: state.pendingInvoicesList.count - 1,
        results: state.pendingInvoicesList.results.filter(item => item.id !== action.payload),
      };
    },
    deleteInvoiceLine: (state, action) => {
      state.invoice.invoice_lines = state.invoice.invoice_lines.filter(
        item => item.id !== action.payload,
      );
    },
    editInvoiceLineMI: (state, action) => {
      const { id, ...rest } = action.payload;
      const lineIndex = state.invoice.invoice_lines.findIndex(item => item.id === id);
      if (lineIndex !== -1) {
        const newInvoiceLines = [...state.invoice.invoice_lines];
        const updatedLine = { ...newInvoiceLines[lineIndex] };
        Object.entries(rest).forEach(([key, value]) => {
          const keys = key.split(".");
          let current = updatedLine;
          keys.forEach((k, index) => {
            if (index === keys.length - 1) {
              current[k] = value;
            } else {
              if (!current[k]) current[k] = {};
              current = current[k];
            }
          });
        });
        newInvoiceLines.splice(lineIndex, 1, updatedLine);
        state.invoice.invoice_lines = newInvoiceLines;
      }
    },
    editInvoiceMI: (state, action) => {
      const newInvoice = { ...state.invoice };
      Object.entries(action.payload).forEach(([key, value]) => {
        const keys = key.split(".");
        let current = newInvoice;
        keys.forEach((k, index) => {
          if (index === keys.length - 1) {
            current[k] = value;
          } else {
            if (!current[k]) {
              current[k] = {};
            }
            current = current[k];
          }
        });
      });
      state.invoice = newInvoice;
    },
    getInvoicesHistoryList: (state, action) => {
      state.invoicesHistoryList = action.payload;
    },
    getInvoicesList: (state, action) => {
      state.invoicesList = action.payload;
    },
    getPendingInvoicesList: (state, action) => {
      state.pendingInvoicesList = action.payload;
    },
    resetBill: state => {
      state.bill = initialState.bill;
    },
    resetBillChargeTypesSelection: state => {
      state.billChargeTypesSelection = initialState.billChargeTypesSelection;
    },
    resetInvoiceIDs: state => {
      state.invoiceIDs = initialState.invoiceIDs;
    },
    resetInvoices: state => {
      state.invoices = initialState.invoices;
    },
    resetInvoicesHistoryParams: state => {
      state.invoicesHistoryParams = { limit: state.invoicesHistoryParams.limit, offset: 0 };
    },
    resetInvoicesParams: state => {
      state.invoicesParams = { limit: state.invoicesParams.limit, offset: 0 };
    },
    resetPendingInvoicesParams: state => {
      state.pendingInvoicesParams = { limit: state.pendingInvoicesParams.limit, offset: 0 };
    },
    setBill: (state, action) => {
      state.bill = action.payload;
    },
    setBillChargeTypesSelection: (state, action) => {
      state.billChargeTypesSelection = action.payload;
    },
    setCurrencies: (state, action) => {
      state.currencies = action.payload;
    },
    setInvoice: (state, action) => {
      state.invoice = action.payload;
    },
    setInvoiceIDs: (state, action) => {
      state.invoiceIDs = action.payload;
    },
    setInvoices: (state, action) => {
      state.invoices = action.payload;
    },
    setInvoicesHistoryList: (state, action) => {
      state.invoicesHistoryList = action.payload;
    },
    setInvoicesHistoryParams: (state, action) => {
      state.invoicesHistoryParams = action.payload;
    },
    setInvoicesParams: (state, action) => {
      state.invoicesParams = {
        ...action.payload,
      };
    },
    setPendingInvoicesList: (state, action) => {
      state.pendingInvoicesList = action.payload;
    },
    setPendingInvoicesParams: (state, action) => {
      state.pendingInvoicesParams = {
        ...action.payload,
      };
    },
    updateInvoice: (state, action) => {
      state.invoice = { ...state.invoice, ...action.payload };
    },
    updateInvoiceDetail: (state, action) => {
      state.invoice = {
        ...state.invoice,
        bill_created_by: action.payload.bill_created_by,
        is_bill_created: action.payload.is_bill_created,
      };
    },
    updateInvoicesHistoryParams: (state, action) => {
      state.invoicesHistoryParams = { ...state.invoicesHistoryParams, ...action.payload };
    },
    updateInvoicesParams: (state, action) => {
      state.invoicesParams = { ...state.invoicesParams, ...action.payload };
    },
    updatePendingInvoicesParams: (state, action) => {
      state.pendingInvoicesParams = { ...state.pendingInvoicesParams, ...action.payload };
    },
  },
});

export const {
  deleteInvoice,
  deleteInvoiceLine,
  editInvoiceLineMI,
  editInvoiceMI,
  getInvoicesHistoryList,
  getInvoicesList,
  getPendingInvoicesList,
  resetBill,
  resetBillChargeTypesSelection,
  resetInvoiceIDs,
  resetInvoices,
  resetInvoicesHistoryParams,
  resetInvoicesParams,
  resetPendingInvoicesParams,
  setBill,
  setBillChargeTypesSelection,
  setCurrencies,
  setInvoice,
  setInvoiceIDs,
  setInvoices,
  setInvoicesHistoryList,
  setInvoicesHistoryParams,
  setInvoicesParams,
  setPendingInvoicesList,
  setPendingInvoicesParams,
  updateInvoice,
  updateInvoiceDetail,
  updateInvoicesHistoryParams,
  updateInvoicesParams,
  updatePendingInvoicesParams,
} = invoiceSlice.actions;

export default invoiceSlice.reducer;
