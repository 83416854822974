import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  operations: { count: 0, results: [] },
  operationsParams: { limit: 10, count: 0 },

  externalSystems: [],
};

const integrationSlice = createSlice({
  name: "integration",
  initialState,
  reducers: {
    resetOperations: state => {
      state.operations = initialState.operations;
    },
    resetOperationsParams: state => {
      state.operationsParams = { limit: state.operationsParams.limit, offset: 0 };
    },
    setOperations: (state, action) => {
      state.operations = { count: action.payload.count, results: action.payload.results };
    },
    setOperationsParams: (state, action) => {
      state.operationsParams = action.payload;
    },
    updateExternalSystemInOperations: (state, action) => {
      state.operations.results.forEach(item => {
        item.externalsystemoperation_set.forEach(obj => {
          if (obj.external_system.id === action.payload.id) {
            obj.external_system.is_config_exists = true;
          }
        });
      });
    },
    updateExternalSystemOperationOptedIn: (state, action) => {
      state.operations.results.forEach(item => {
        item.externalsystemoperation_set.forEach(obj => {
          if (obj.id === action.payload) {
            obj.opted_in = true;
          }
        });
      });
    },
    updateOperationsParams: (state, action) => {
      state.operationsParams = { ...state.operationsParams, ...action.payload };
    },
    getExternalSystems: (state, action) => {
      state.externalSystems = action.payload;
    },
    resetExternalSystems: state => {
      state.externalSystems = initialState.externalSystems;
    },
  },
});

export const {
  resetOperations,
  resetOperationsParams,
  setOperations,
  setOperationsParams,
  updateExternalSystemInOperations,
  updateExternalSystemOperationOptedIn,
  updateOperationsParams,
  getExternalSystems,
  resetExternalSystems,
} = integrationSlice.actions;

export default integrationSlice.reducer;
