/* eslint-disable no-unused-vars */

export const defaultLayout = {
  loader: {
    display: true,
    type: "default",
  },
  scrolltop: {
    display: false,
  },
  illustrations: {
    componentName: "illustrations",
    set: "dozzy-1",
  },
  header: {
    display: true,
    width: "fluid",
    fixed: {
      desktop: true,
      tabletAndMobile: true,
    },
    menuIcon: "svg",
  },
  megaMenu: {
    display: false,
  },
  aside: {
    display: true,
    theme: "dark",
    menu: "main",
    fixed: true,
    minimized: false,
    minimize: true,
    hoverable: false,
    menuIcon: "svg",
  },
  content: {
    width: "fluid",
    layout: "default",
  },
  toolbar: {
    display: false,
    width: "fluid",
    fixed: {
      desktop: true,
      tabletAndMobileMode: true,
    },
    layout: "toolbar1",
    layouts: {
      toolbar1: {
        height: "55px",
        heightAndTabletMobileMode: "55px",
      },
      toolbar2: {
        height: "55px",
        heightAndTabletMobileMode: "55px",
      },
      toolbar3: {
        height: "55px",
        heightAndTabletMobileMode: "55px",
      },
      toolbar4: {
        height: "55px",
        heightAndTabletMobileMode: "55px",
      },
      toolbar5: {
        height: "55px",
        heightAndTabletMobileMode: "55px",
      },
    },
  },
  footer: {
    width: "fluid",
  },
  sidebar: {
    display: true,
    toggle: true,
    shown: true,
    content: "general",
    bgColor: "bg-white",
    displayFooter: true,
    displayFooterButton: true,
  },
  main: {
    body: {
      backgroundImage: "",
      class: "",
    },
    primaryColor: "",
    darkSkinEnabled: false,
    type: "blank",
    iconType: "duotone",
  },
  pageTitle: {
    display: true,
    breadCrumbs: true,
    description: true,
    layout: "default",
    direction: "row",
    responsive: false,
    responsiveBreakpoint: "lg",
    responsiveTarget: "",
  },
};

export const layoutCSSClasses = {
  header: [],
  headerContainer: [],
  headerMobile: [],
  headerMenu: [],
  aside: [],
  asideMenu: [],
  asideToggle: [],
  sidebar: [],
  toolbar: [],
  toolbarContainer: [],
  content: [],
  contentContainer: [],
  footerContainer: [],
  pageTitle: [],
};

export const layoutHTMLAttributes = {
  asideMenu: new Map(),
  headerMobile: new Map(),
  headerMenu: new Map(),
  headerContainer: new Map(),
  pageTitle: new Map(),
};

export const layoutCSSVariables = {
  body: new Map(),
};
