import * as Yup from "yup";
import clsx from "clsx";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useCallback, useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { PasswordMeterComponent } from "../../../_metronic/assets/ts/components";
import { useSignupMutation, useValidateTokenQuery } from "../../../store/apiSlice/authApiSlice";

const initialValues = {
  first_name: "",
  last_name: "",
  password: "",
  changepassword: "",
};

const registrationSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("First name is required"),
  last_name: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Last name is required"),
  password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
  changepassword: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password confirmation is required")
    .oneOf([Yup.ref("password")], "Password and Confirm Password didn't match"),
});

export function Registration() {
  const { token } = useParams();

  const navigate = useNavigate();

  const [shouldFetch, setShouldFetch] = useState(false);

  const { error: validateTokenError, isLoading } = useValidateTokenQuery(token, {
    skip: !shouldFetch,
  });

  const [
    signup,
    { data: signupData, error: signupError, isLoading: signupLoading, isSuccess: signupIsSuccess },
  ] = useSignupMutation();

  const displayError = useCallback(
    errorMsg => {
      toast.error(errorMsg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
      navigate("/");
    },
    [navigate],
  );

  useEffect(() => {
    if (validateTokenError || signupError) {
      const defaultMsg = "Something went wrong";
      const errorMsg =
        validateTokenError?.data?.detail ?? signupError?.data?.token[0] ?? defaultMsg;
      displayError(errorMsg);
    }
    if (signupIsSuccess) {
      toast.success(signupData?.detail, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
      navigate("/");
    }
    if (token) {
      setShouldFetch(true);
    }
  }, [token, validateTokenError, signupError, signupIsSuccess, signupData, displayError, navigate]);

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        await signup({ ...values, token });
      } catch (error) {
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    PasswordMeterComponent.bootstrap();
  }, []);

  if (!token) return <Navigate to="/" />;

  return (
    !isLoading && (
      <div className="d-flex flex-column flex-lg-row flex-column-fluid h-100">
        <div className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-2">
          <div className="d-flex flex-column flex-center py-15 px-5 px-md-15 w-100">
            <Link to="/" className="mt-25">
              <img
                alt="Orderlink Logo"
                src={toAbsoluteUrl("/media/logos/orderlink.png")}
                className="h-50px"
              />
            </Link>
            <img
              className="mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20"
              src={toAbsoluteUrl("/media/illustrations/sigma-1/17.png")}
              alt=""
            />
          </div>
        </div>

        <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 py-10 order-1">
          <div className="d-flex flex-center flex-column flex-lg-row-fluid">
            <form
              id="kt_login_signup_form"
              className="form fv-plugins-bootstrap5 fv-plugins-framework w-75 w-lg-50 py-10"
              noValidate
              onSubmit={formik.handleSubmit}
            >
              <div className="text-center mb-11">
                <h1 className="text-dark fw-bolder mb-3">Complete Your Sign Up</h1>
              </div>

              {formik.status && (
                <div className="mb-lg-15 alert alert-danger">
                  <div className="alert-text font-weight-bold">{formik.status}</div>
                </div>
              )}

              <div className="fv-row mb-8">
                <label className="form-label fw-bolder text-dark fs-6">First name</label>
                <input
                  placeholder="First name"
                  type="text"
                  autoComplete="off"
                  {...formik.getFieldProps("first_name")}
                  className={clsx(
                    "form-control bg-transparent",
                    { "is-valid": formik.touched.first_name && !formik.errors.first_name },
                    { "is-invalid": formik.touched.first_name && formik.errors.first_name },
                  )}
                />
                {formik.touched.first_name && formik.errors.first_name && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.first_name}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className="fv-row mb-8">
                <label className="form-label fw-bolder text-dark fs-6">Last name</label>
                <input
                  placeholder="Last name"
                  type="text"
                  autoComplete="off"
                  {...formik.getFieldProps("last_name")}
                  className={clsx(
                    "form-control bg-transparent",
                    { "is-valid": formik.touched.last_name && !formik.errors.last_name },
                    { "is-invalid": formik.touched.last_name && formik.errors.last_name },
                  )}
                />
                {formik.touched.last_name && formik.errors.last_name && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.last_name}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className="fv-row mb-8" data-kt-password-meter="true">
                <div className="mb-1">
                  <label className="form-label fw-bolder text-dark fs-6">Password</label>
                  <div className="position-relative mb-3">
                    <input
                      type="password"
                      placeholder="Password"
                      autoComplete="off"
                      {...formik.getFieldProps("password")}
                      className={clsx(
                        "form-control bg-transparent",
                        { "is-valid": formik.touched.password && !formik.errors.password },
                        { "is-invalid": formik.touched.password && formik.errors.password },
                      )}
                    />
                    {formik.touched.password && formik.errors.password && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">{formik.errors.password}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    className="d-flex align-items-center mb-3"
                    data-kt-password-meter-control="highlight"
                  >
                    <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                    <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                    <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                    <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
                  </div>
                </div>
                <div className="text-muted">
                  Use 8 or more characters with a mix of letters, numbers & symbols.
                </div>
              </div>

              <div className="fv-row mb-5">
                <label className="form-label fw-bolder text-dark fs-6">Confirm Password</label>
                <input
                  type="password"
                  placeholder="Password confirmation"
                  autoComplete="off"
                  {...formik.getFieldProps("changepassword")}
                  className={clsx(
                    "form-control bg-transparent",
                    { "is-valid": formik.touched.changepassword && !formik.errors.changepassword },
                    { "is-invalid": formik.touched.changepassword && formik.errors.changepassword },
                  )}
                />
                {formik.touched.changepassword && formik.errors.changepassword && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.changepassword}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className="text-center">
                <button
                  type="submit"
                  id="kt_sign_up_submit"
                  className="btn btn-lg btn-primary w-100 mb-5"
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  {!signupLoading && <span className="indicator-label">Submit</span>}
                  {signupLoading && (
                    <span className="indicator-progress" style={{ display: "block" }}>
                      Please wait...{" "}
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
                <Link to="/">
                  <button
                    type="button"
                    id="kt_login_signup_form_cancel_button"
                    className="btn btn-lg btn-light-primary w-100 mb-5"
                  >
                    Cancel
                  </button>
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  );
}
